import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useProgressBarStore } from "../../../../../../store/hooks";
import { useParams } from "react-router-dom";

import {
  IDrug,
  ISecondStage,
} from "../../../../../../interfaces/user/draftCase/secondStage";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { useCaseApi } from "../../../../../../apiHooks";
import { validationSchema } from "./secondStage.helper";
import { setAllFieldsTouched } from "../stages.helper";

export const useSecondStageVm = () => {
  const [formInjection, setFormInjection] = useState<boolean | null>(null);
  const progressBarStore = useProgressBarStore();
  const { secondStage, isClicked, firstStage } = progressBarStore;
  const {
    postAbdominalFilesSecond,
    deleteAbdominalFilesSecond,
    postLiverFilesSecondStage,
    deleteLiverFilesSecondStage,
    postDrugInteractionFilesSecond,
    deleteDrugInteractionFilesSecond,
  } = useCaseApi();
  const params = useParams();
  const { id } = params;

  const formik = useFormik<ISecondStage>({
    enableReinitialize: true,
    initialValues: {
      dateOfDetection: null,
      placeOfDetection: -1,
      routeOfInfection: "",
      routeOfInfectionDesc: "",
      scaleScore: "",
      metavirDesc: "",
      apriDesc: "",
      clinicalSignsOfCirrhosis: "",
      extrahepaticManifestationsOfHCV: "",
      hcvRna: "",
      hcvRnaUnit: "",
      rbc: "",
      hgb: "",
      htc: "",
      plt: "",
      wbc: "",
      mcv: "",
      mch: "",
      mchc: "",
      aiat: "",
      albumin: "",
      bilirubin: "",
      prothrombin: "",
      prothrombinUnit: "",
      creatinine: "",
      egfr: "",
      antiHiv: "",
      hbs: "",
      abdominalUltrasound: "",
      abdominalUltrasoundCheckbox: false,
      liverFibrosis: "",
      fibroSkanDesc: "",
      apriLiverDesc: "",
      fibLiverDesc: "",
      liverBiopsy: false,
      liverBiopsyDesc: "",
      liverBiopsyCheckbox: false,
      hcvGenotype: "",
      hcvGenotypeMix: "",
      presenceOfComorbidities: "",
      dataHiv: "",
      dataHbv: "",
      previousHistory: "",
      otherElements: [],
      otherElementsDesc: "",
      patientPreviouslyTreatedWithAntivirals: "",
      patientPreviouslyTreatedCount: -1,
      patientPreviouslyTreatedItems: [],
      drugs: [],
      potentialDrugInteractions: "",
    },

    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {},
  });

  const { errors, setFieldValue, validateForm, setFieldTouched } = formik;

  useEffect(() => {
    if (formInjection) {
      if (Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.THIRD);
      } else if (
        Object.keys(formik.touched).length !== 0 &&
        Object.keys(errors).length === 0
      ) {
        progressBarStore.setMaxEditStage(Stages.SECOND);
      }
    }
  }, [errors, formInjection, progressBarStore, formik.touched]);

  useEffect(() => {
    if (progressBarStore.secondStage && id) {
      setFieldValue(
        "dateOfDetection",
        progressBarStore.secondStage.dateOfDetection
      );
      setFieldValue(
        "placeOfDetection",
        progressBarStore.secondStage.placeOfDetection ?? -1
      );
      setFieldValue(
        "routeOfInfection",
        progressBarStore.secondStage.routeOfInfection ?? ""
      );
      setFieldValue(
        "routeOfInfectionDesc",
        progressBarStore.secondStage.routeOfInfectionDesc ?? ""
      );
      setFieldValue(
        "scaleScore",
        progressBarStore.secondStage.scaleScore ?? ""
      );
      setFieldValue(
        "metavirDesc",
        progressBarStore.secondStage.metavirDesc ?? ""
      );
      setFieldValue("apriDesc", progressBarStore.secondStage.apriDesc ?? "");
      setFieldValue(
        "clinicalSignsOfCirrhosis",
        progressBarStore.secondStage.clinicalSignsOfCirrhosis ?? ""
      );
      setFieldValue(
        "extrahepaticManifestationsOfHCV",
        progressBarStore.secondStage.extrahepaticManifestationsOfHCV ?? ""
      );
      setFieldValue("hcvRna", progressBarStore.secondStage.hcvRna ?? "");
      setFieldValue(
        "hcvRnaUnit",
        progressBarStore.secondStage.hcvRnaUnit ?? ""
      );
      setFieldValue("rbc", progressBarStore.secondStage.rbc ?? "");
      setFieldValue("hgb", progressBarStore.secondStage.hgb ?? "");
      setFieldValue("htc", progressBarStore.secondStage.htc ?? "");
      setFieldValue("plt", progressBarStore.secondStage.plt ?? "");
      setFieldValue("wbc", progressBarStore.secondStage.wbc ?? "");
      setFieldValue("mcv", progressBarStore.secondStage.mcv ?? "");
      setFieldValue("mch", progressBarStore.secondStage.mch ?? "");
      setFieldValue("mchc", progressBarStore.secondStage.mchc ?? "");
      setFieldValue("aiat", progressBarStore.secondStage.aiat ?? "");
      setFieldValue("albumin", progressBarStore.secondStage.albumin ?? "");
      setFieldValue("bilirubin", progressBarStore.secondStage.bilirubin ?? "");
      setFieldValue(
        "prothrombin",
        progressBarStore.secondStage.prothrombin ?? ""
      );
      setFieldValue(
        "prothrombinUnit",
        progressBarStore.secondStage.prothrombinUnit ?? ""
      );
      setFieldValue(
        "creatinine",
        progressBarStore.secondStage.creatinine ?? ""
      );
      setFieldValue("egfr", progressBarStore.secondStage.egfr ?? "");
      setFieldValue("antiHiv", progressBarStore.secondStage.antiHiv ?? "");
      setFieldValue("hbs", progressBarStore.secondStage.hbs ?? "");
      setFieldValue(
        "abdominalUltrasound",
        progressBarStore.secondStage.abdominalUltrasound ?? ""
      );
      setFieldValue(
        "abdominalUltrasoundCheckbox",
        progressBarStore.secondStage.abdominalUltrasoundCheckbox
      );
      setFieldValue(
        "liverFibrosis",
        progressBarStore.secondStage.liverFibrosis ?? ""
      );
      setFieldValue(
        "fibroSkanDesc",
        progressBarStore.secondStage.fibroSkanDesc ?? ""
      );
      setFieldValue(
        "apriLiverDesc",
        progressBarStore.secondStage.apriLiverDesc ?? ""
      );
      setFieldValue(
        "fibLiverDesc",
        progressBarStore.secondStage.fibLiverDesc ?? ""
      );
      setFieldValue("liverBiopsy", progressBarStore.secondStage.liverBiopsy);
      setFieldValue(
        "liverBiopsyDesc",
        progressBarStore.secondStage.liverBiopsyDesc ?? ""
      );
      setFieldValue(
        "liverBiopsyCheckbox",
        progressBarStore.secondStage.liverBiopsyCheckbox
      );
      setFieldValue(
        "hcvGenotype",
        progressBarStore.secondStage.hcvGenotype ?? ""
      );
      setFieldValue(
        "hcvGenotypeMix",
        progressBarStore.secondStage.hcvGenotypeMix ?? ""
      );
      setFieldValue(
        "presenceOfComorbidities",
        progressBarStore.secondStage.presenceOfComorbidities ?? ""
      );
      setFieldValue("dataHiv", progressBarStore.secondStage.dataHiv ?? "");
      setFieldValue("dataHbv", progressBarStore.secondStage.dataHbv ?? "");
      setFieldValue(
        "previousHistory",
        progressBarStore.secondStage.previousHistory ?? ""
      );
      setFieldValue(
        "otherElements",
        progressBarStore.secondStage.otherElements ?? []
      );
      setFieldValue(
        "otherElementsDesc",
        progressBarStore.secondStage.otherElementsDesc ?? ""
      );
      setFieldValue(
        "patientPreviouslyTreatedWithAntivirals",
        progressBarStore.secondStage.patientPreviouslyTreatedWithAntivirals ??
          ""
      );
      setFieldValue(
        "patientPreviouslyTreatedCount",
        progressBarStore.secondStage.patientPreviouslyTreatedCount ?? -1
      );
      setFieldValue(
        "patientPreviouslyTreatedItems",
        progressBarStore.secondStage.patientPreviouslyTreatedItems ?? []
      );
      setFieldValue(
        "drugs",
        progressBarStore.secondStage.drugs
          ? JSON.parse(JSON.stringify(progressBarStore.secondStage.drugs))
          : []
      );
      setFieldValue(
        "potentialDrugInteractions",
        progressBarStore.secondStage.potentialDrugInteractions ?? ""
      );
    }
  }, [progressBarStore, setFieldValue, id]);

  useEffect(() => {
    if (formik.isValid) {
      const secondStageConvert: ISecondStage = {
        dateOfDetection: formik.values.dateOfDetection,
        placeOfDetection: formik.values.placeOfDetection,
        routeOfInfection: formik.values.routeOfInfection,
        routeOfInfectionDesc: formik.values.routeOfInfectionDesc,
        scaleScore: formik.values.scaleScore,
        metavirDesc: formik.values.metavirDesc,
        apriDesc: formik.values.apriDesc,
        clinicalSignsOfCirrhosis: formik.values.clinicalSignsOfCirrhosis,
        extrahepaticManifestationsOfHCV:
          formik.values.extrahepaticManifestationsOfHCV,
        hcvRna: formik.values.hcvRna,
        hcvRnaUnit: formik.values.hcvRnaUnit,
        rbc: formik.values.rbc,
        hgb: formik.values.hgb,
        htc: formik.values.htc,
        plt: formik.values.plt,
        wbc: formik.values.wbc,
        mcv: formik.values.mcv,
        mch: formik.values.mch,
        mchc: formik.values.mchc,
        aiat: formik.values.aiat,
        albumin: formik.values.albumin,
        bilirubin: formik.values.bilirubin,
        prothrombin: formik.values.prothrombin,
        prothrombinUnit: formik.values.prothrombinUnit,
        creatinine: formik.values.creatinine,
        egfr: formik.values.egfr,
        antiHiv: formik.values.antiHiv,
        hbs: formik.values.hbs,
        abdominalUltrasound: formik.values.abdominalUltrasound,
        abdominalUltrasoundFiles: progressBarStore.secondStage
          ? progressBarStore.secondStage.abdominalUltrasoundFiles
          : [],
        abdominalUltrasoundCheckbox: formik.values.abdominalUltrasoundCheckbox,
        liverFibrosis: formik.values.liverFibrosis,
        fibroSkanDesc: formik.values.fibroSkanDesc,
        apriLiverDesc: formik.values.apriLiverDesc,
        fibLiverDesc: formik.values.fibLiverDesc,
        liverBiopsy: formik.values.liverBiopsy,
        liverBiopsyDesc: formik.values.liverBiopsy
          ? formik.values.liverBiopsyDesc
          : "",
        liverBiopsyFiles: progressBarStore.secondStage
          ? progressBarStore.secondStage.liverBiopsyFiles
          : [],
        liverBiopsyCheckbox: formik.values.liverBiopsyCheckbox,
        hcvGenotype: formik.values.hcvGenotype,
        hcvGenotypeMix: formik.values.hcvGenotypeMix,
        presenceOfComorbidities: formik.values.presenceOfComorbidities,
        dataHiv: formik.values.dataHiv,
        dataHbv: formik.values.dataHbv,
        previousHistory: formik.values.previousHistory,
        otherElements: formik.values.otherElements,
        otherElementsDesc: formik.values.otherElements?.includes("inne")
          ? formik.values.otherElementsDesc
          : "",
        patientPreviouslyTreatedWithAntivirals:
          formik.values.patientPreviouslyTreatedWithAntivirals,
        patientPreviouslyTreatedCount:
          formik.values.patientPreviouslyTreatedCount,
        patientPreviouslyTreatedItems:
          formik.values.patientPreviouslyTreatedItems,
        drugs: formik.values.drugs,
        potentialDrugInteractions: formik.values.potentialDrugInteractions,
        potentialDrugInteractionFiles: progressBarStore.secondStage
          ? progressBarStore.secondStage.potentialDrugInteractionFiles
          : [],
      };
      setFormInjection(true);
      progressBarStore.setSecondStage({ ...secondStageConvert });
    } else {
      progressBarStore.setMaxEditStage(Stages.SECOND);
    }
  }, [
    formik.errors,
    formik.values,
    progressBarStore,
    formik.touched,
    formik.isValid,
    formik.dirty,
  ]);

  const setOption = (value: number) => {
    setFieldValue("placeOfDetection", value);
  };

  const setOptionMetavir = (value: string) => {
    setFieldValue("metavirDesc", value);
  };

  const setOptionRnaUnit = (value: string) => {
    setFieldValue("hcvRnaUnit", value);
  };
  const setOptionGenotype = (value: string) => {
    setFieldValue("hcvGenotype", value);
  };

  const setOptionPreviouslyTreatedCount = (value: number) => {
    setFieldValue("patientPreviouslyTreatedCount", value);

    if (
      value > 0 &&
      secondStage &&
      secondStage.patientPreviouslyTreatedItems.length === 0
    ) {
      setFieldValue("patientPreviouslyTreatedItems", [
        ...Array(value).map((el) => ({
          year: null,
          description: "",
          response: "",
        })),
      ]);
    } else if (value === 0) {
      setFieldValue("patientPreviouslyTreatedItems", []);
    }
    if (
      secondStage &&
      secondStage.patientPreviouslyTreatedItems.length > 0 &&
      value !== -1
    ) {
      if (secondStage.patientPreviouslyTreatedItems.length > value) {
        setFieldValue("patientPreviouslyTreatedItems", [
          ...formik.values.patientPreviouslyTreatedItems.slice(0, value),
        ]);
      } else if (
        secondStage.patientPreviouslyTreatedItems.length > 0 &&
        secondStage.patientPreviouslyTreatedItems.length < value
      ) {
        const diff = value - secondStage.patientPreviouslyTreatedItems.length;
        setFieldValue("patientPreviouslyTreatedItems", [
          ...formik.values.patientPreviouslyTreatedItems,
          ...Array(diff).map((el) => ({
            year: null,
            description: "",
            response: "",
          })),
        ]);
      }
    }
  };

  const addFile = async (file: File | null) => {
    if (file && id) {
      await postAbdominalFilesSecond(+id, file);
    }
  };

  const removeFile = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteAbdominalFilesSecond(+id, idFile);
    }
  };

  const addFileLiver = async (file: File | null) => {
    if (file && id) {
      await postLiverFilesSecondStage(+id, file);
    }
  };
  const removeFileLiver = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteLiverFilesSecondStage(+id, idFile);
    }
  };

  const addDrugInteractionFile = async (file: File | null) => {
    if (file && id) {
      await postDrugInteractionFilesSecond(+id, file);
    }
  };

  const removeDrugInteractionFile = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteDrugInteractionFilesSecond(+id, idFile);
    }
  };

  const addDrug = (drug: IDrug) => {
    if (
      formik.values.drugs.filter((item) => item.id === drug.id).length === 0
    ) {
      setFieldValue("drugs", [...formik.values.drugs, drug]);
      if (progressBarStore.secondStage) {
        progressBarStore.setSecondStage({
          ...progressBarStore.secondStage,
          drugs: [...formik.values.drugs, drug],
        });
      }
    }
  };

  const deleteDrug = (id: string | number) => {
    if (id) {
      setFieldValue("drugs", [
        ...formik.values.drugs.filter((el) => el.id !== id),
      ]);
      if (progressBarStore.secondStage) {
        progressBarStore.setSecondStage({
          ...progressBarStore.secondStage,
          drugs: [...formik.values.drugs.filter((el) => el.id !== id)],
        });
      }
    }
  };

  const handleCheckbox = (value: string) => {
    const newValues = [...formik.values.otherElements];
    const newOperation = newValues.filter((el) => el !== value);
    if (value) {
      if (formik.values.otherElements?.includes(value)) {
        setFieldValue("otherElements", newOperation);
      } else if (!formik.values.otherElements?.includes(value)) {
        setFieldValue("otherElements", [...newValues, value]);
      }
    }
  };

  useEffect(() => {
    if (formik.values.routeOfInfection === "nieznana droga zakażenia") {
      setFieldValue("routeOfInfectionDesc", "");
    }
    if (formik.values.scaleScore === "wynik w skali METAVIR") {
      setFieldValue("apriDesc", "");
    }
    if (formik.values.scaleScore === "APRI lub FIB-4") {
      setFieldValue("metavirDesc", "");
    }
    if (formik.values.liverFibrosis === "FIBROSKAN/ELASTIGRAFIA [kPa]") {
      setFieldValue("apriLiverDesc", "");
      setFieldValue("fibLiverDesc", "");
    }
    if (formik.values.liverFibrosis === "APRI") {
      setFieldValue("fibroSkanDesc", "");
      setFieldValue("fibLiverDesc", "");
    }

    if (formik.values.liverFibrosis === "FIB-4") {
      setFieldValue("apriLiverDesc", "");
      setFieldValue("fibroSkanDesc", "");
    }
  }, [formik.values, setFieldValue]);

  useEffect(() => {
    if (formik.values.patientPreviouslyTreatedWithAntivirals === "nie") {
      setFieldValue("patientPreviouslyTreatedItems", []);
      setFieldValue("patientPreviouslyTreatedCount", -1);
    }
  }, [formik.values.patientPreviouslyTreatedWithAntivirals, setFieldValue]);

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched);
      void validateForm();
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values]);

  return {
    formik,
    setOption,
    abdominalUltrasoundFiles:
      secondStage && secondStage.abdominalUltrasoundFiles
        ? secondStage.abdominalUltrasoundFiles
        : [],
    potentialDrugInteractionFiles:
      secondStage && secondStage.potentialDrugInteractionFiles
        ? secondStage.potentialDrugInteractionFiles
        : [],
    liverBiopsyFiles:
      secondStage && secondStage.liverBiopsyFiles
        ? secondStage.liverBiopsyFiles
        : [],
    addFile,
    removeFile,
    firstStage,
    addFileLiver,
    removeFileLiver,
    addDrug,
    deleteDrug,
    handleCheckbox,
    setOptionMetavir,
    setOptionRnaUnit,
    setOptionGenotype,
    setOptionPreviouslyTreatedCount,
    addDrugInteractionFile,
    removeDrugInteractionFile,
  };
};
