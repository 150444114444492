import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import { useFourthStageVm } from "./useFourthStage.vm";
import { Input } from "../../../../../shared/ui/input/Input";
import theme from "../../../../../../theme/theme";
import { MultipleFileUpload } from "../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import { FileSource } from "../../../../../shared/uploadFiles/FileUploadLayout";
import { Checkbox } from "../../../../../shared/ui/checkbox/Checkbox";

export const FourthStage = observer(() => {
  const { formik, conclusionFiles, removeConclusionFile, addConclusionFile } =
    useFourthStageVm();
  return (
    <Grid
      container
      maxWidth={"714px"}
      justifyContent={"flex-end"}
      display={"flex"}
      margin={"0 auto"}
      mt={7}
    >
      <Grid item xs={12} alignSelf={"center"}>
        <Grid item xs={12} mt={2}>
          <Input
            type={"textarea"}
            maxWidth={"100%"}
            width={"100%"}
            name={"infoAboutTherapy"}
            isUpperCase={true}
            isRequired={true}
            label={"informacje o przebiegu terapii"}
            colorLabel={theme.colors.grey1}
            value={formik.values.infoAboutTherapy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={
              "Wpisz informacje o przebiegu terapii np. parametry laboratoryjne, samopoczucie pacjenta..."
            }
            isError={
              formik.touched.infoAboutTherapy &&
              Boolean(formik.errors.infoAboutTherapy)
            }
          />
        </Grid>
        <Grid item xs={12} mt={3}>
          <Input
            type={"textarea"}
            maxWidth={"100%"}
            width={"100%"}
            name={"conclusion"}
            isUpperCase={true}
            isRequired={true}
            label={"wnioski"}
            colorLabel={theme.colors.grey1}
            value={formik.values.conclusion}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wnioski..."}
            isError={
              formik.touched.conclusion && Boolean(formik.errors.conclusion)
            }
          />
        </Grid>
        <Grid item xs={12} mt={3} mb={6}>
          <Checkbox
            fontWeight={"700"}
            id={"conclusionCheckbox"}
            name={"conclusionCheckbox"}
            fontSize={"12px"}
            textColor={theme.colors.abbvie}
            label={
              "Oświadczam, że na załączonych zdjęciach nie znajdują sie dane pacjenta*"
            }
            checked={formik.values.conclusionCheckbox}
            onChange={() =>
              formik.setFieldValue(
                "conclusionCheckbox",
                !formik.values.conclusionCheckbox
              )
            }
          />
          <MultipleFileUpload
            id={"conclusion"}
            files={conclusionFiles}
            removeFile={removeConclusionFile}
            addFile={addConclusionFile}
            disabled={!formik.values.conclusionCheckbox}
            maxFileSize={10}
            extensions={["pdf", "jpg", "jpeg", "png", "gif"]}
            fileType={["application/pdf", "image"]}
            fileSource={FileSource.STATE}
            size={"small"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
