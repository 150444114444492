import {makeAutoObservable} from "mobx";

import {AuthStore} from "./authStore";
import {LoadersStore} from "./loadersStore";
import {UsersStore} from "./usersStore";
import {RolesStore} from "./rolesStore";
import {OthersStore} from "./othersStore";
import {NotificationStore} from "./notificationsStore/notificationsStore";
import {CasesStore} from "./casesStore";
import {HistoryStore} from "./historyStore";
import {WindowDimensionsStore} from "./windowDimensionsStore";
import {ProgressBarStore} from "./progressBarStore";
import {ProfileStore} from "./profileStore";
import {HomeStore} from "./homeStore";
import {CaseStore} from "./caseStore";
import {AreasStore} from "./areasStore";
import {FormsStore} from "./formsStore";
import {CaseSettingsStore} from "./caseSettingsStore";
import {RzsStore} from "./forms/rzsStore";
import {HcvStore} from "./forms/hcvStore";
import {LzssStore} from "./forms/lzssStore";
import {LzsrStore} from "./forms/lzsrStore";
import {ZzskStore} from "./forms/zzskStore";

export class RootStore {
  public authStore = new AuthStore();
  public casesStore = new CasesStore();
  public historyStore = new HistoryStore();
  public loadersStore = new LoadersStore();
  public usersStore = new UsersStore();
  public rolesStore = new RolesStore();
  public othersStore = new OthersStore();
  public notificationsStore = new NotificationStore();
  public windowDimensionsStore = new WindowDimensionsStore();
  public progressBarStore = new ProgressBarStore();
  public profileStore = new ProfileStore();
  public homeStore = new HomeStore();
  public caseStore = new CaseStore();
  public areasStore = new AreasStore();
  public formsStore = new FormsStore();
  // after refactor stores
  public caseSettingsStore = new CaseSettingsStore(this);
  public rzsStore = new RzsStore();
  public hcvStore = new HcvStore();
  public lzssStore = new LzssStore();
  public lzsrStore = new LzsrStore();
  public zzskStore = new ZzskStore();

  constructor() {
    makeAutoObservable(this, {
      authStore: false,
      casesStore: false,
      historyStore: false,
      loadersStore: false,
      usersStore: false,
      progressBarStore: false,
      rolesStore: false,
      othersStore: false,
      notificationsStore: false,
      windowDimensionsStore: false,
      profileStore: false,
      homeStore: false,
      caseStore: false,
      areasStore: false,
      formsStore: false,
      // after refactor stores
      caseSettingsStore: false,
      rzsStore: false,
      hcvStore: false,
      lzssStore: false,
      lzsrStore: false,
      zzskStore: false,
    });
  }
}
