import styled from "styled-components";
import theme from "../../../../../theme/theme";

export interface IButton {
  $disable?: string;
}
export const ButtonTransparent = styled.button<IButton>`
  border: none;
  height: 32px;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  color: ${theme.colors.abbvie};
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 14.4px */
  letter-spacing: 2.4px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.$disable === "true" &&
    `
      cursor: not-allowed;
      color: ${theme.colors.grey2};
  `};
`;

export const DraftBtnContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: fixed; /* Fix the position relative to the viewport */
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1000;

  svg {
    width: 13px;
    height: 13px;

    path {
      fill: ${theme.colors.abbvie};
    }
  }
`;
