import * as yup from "yup";

// hooks
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useCaseSettingsStore, useLzsrStore, } from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";

// types, helpers
import { TLzsRinvoqStage01 } from "./type";

import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  criteria: yup.string().oneOf(['tak'], 'Nie można wypełnić formularza, ponieważ pacjent nie spełnił kryteriów').required('To pole jest wymagane'),
  criteriaPoints: yup
    .array()
    .when("criteria", {
      is: (value: string) => value === 'tak',
      then: (schema) => schema.min(1).required(),
    }),
  points: yup.number()
    .nullable(),
  initials: yup.string().max(3).required(),
  gender: yup.string().required(),
  age: yup.number().integer().positive().required(),
  weightKg: yup.number().positive().required(),
  heightCm: yup.number().positive().required(),
  bmi: yup.number().required(),
  durationLzs: yup.number().nullable(),
  comorbidities: yup.array().min(1).required(),
  comorbiditiesOther: yup
    .string()
    .max(256)
    .nullable()
    .when("comorbidities", {
      is: (value: string[]) => value?.includes("Inne"),
      then: (schema) => schema.required(),
    }),
  htz: yup.string().nullable(),
  contraception: yup.string().nullable(),
  smoking: yup.string().required(),
  packYears: yup.number().nullable(),
  psoriasis: yup.string().nullable(),
  accp: yup.string().nullable(),
});

const initialValues: TLzsRinvoqStage01 = {
  area: 0,
  form: 0,
  title: "",
  criteria: "",
  criteriaPoints: [],
  points: 0,
  initials: "",
  gender: "",
  age: null,
  weightKg: null,
  heightCm: null,
  bmi: null,
  durationLzs: null,
  comorbidities: [],
  comorbiditiesOther: '',
  htz: "",
  contraception: "",
  smoking: "",
  packYears: null,
  psoriasis: "",
  accp: "",
};

export const useStage01ViewModel = () => {
  // params and location
  const params = useParams();
  const location = useLocation();
  const {area, form, caseId} = params;
  const navigate = useNavigate();

  // define is create or edit case page
  const isCreateCasePage = !caseId && location.pathname?.includes("add");

  // rzs store
  const {firstStage} = useLzsrStore();

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {userPrescribedAreas, currentStage} = caseSettingsStore;

  // define API calls
  const caseApi = useCaseApi();

  const areaId = userPrescribedAreas?.find(
    (areaObj) => areaObj.name === area
  )?.id;

  const formId = userPrescribedAreas
    ?.find((areaObj) => areaObj.name === area)
    ?.forms.find((formObj) => formObj.name === form)?.id;

  const valuesObj = isCreateCasePage
    ? JSON.parse(JSON.stringify(initialValues))
    : JSON.parse(JSON.stringify(firstStage));

  const formik = useFormik<TLzsRinvoqStage01>({
    initialValues: {
      ...valuesObj,
      area: areaId || 0,
      form: formId || 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        psoriasis: values.psoriasis || null,
        htz: values.htz || null,
        accp: values.accp || null,
        contraception: values.contraception || null,
        packYears:
          typeof values.packYears === "number"
            ? values.packYears
            : values.packYears || null,
        durationLzs:
          typeof values.durationLzs === "number"
            ? values.durationLzs
            : values.durationLzs || null,
        comorbiditiesOther: !values.comorbidities?.includes("Inne")
          ? null
          : values.comorbiditiesOther,
      };

      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (isCreateCasePage) {
        const createNewCaseResponse = await caseApi.createNewCase(
          newValues,
          area as EnAreaNames,
          form as EnFormNames
        );

        if (createNewCaseResponse) {
          caseSettingsStore.increaseCurrentStage();
          navigate(`/case/edit/${area}/${form}/${createNewCaseResponse}`);
        } else {
          throw new Error(
            "Formik submit first stage error, some rules are broken"
          );
        }
      } else {
        if (!caseId) throw new Error("Case ID is missing");
        if (!currentStage) throw new Error("Current stage is missing");

        await caseApi.updateCase({
          area: area as EnAreaNames,
          form: form as EnFormNames,
          caseId,
          value: newValues,
          stage: currentStage,
        });
      }
    },
  });
  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  useEffect(() => {
    if (formik.values.weightKg && formik.values.heightCm) {
      const {weightKg, heightCm} = formik.values;
      const bmi =
        weightKg > 0 && heightCm > 0
          ? Math.floor(weightKg / (heightCm * 0.01) ** 2)
          : 0;
      setFieldValue("bmi", bmi);
    } else {
      setFieldValue("bmi", null);
    }
  }, [
    formik.values.weightKg,
    formik.values.heightCm,
    formik.values,
    setFieldValue,
  ]);

  useEffect(() => {
    if (formik.values.criteriaPoints) {
      let result = formik.values.criteriaPoints.length
      if (formik.values.criteriaPoints.includes('A łuszczyca obecnie – 2 pkt')) {
        result = result + 1
      }

      setFieldValue("points", result);
    }
  }, [setFieldValue, formik.values.criteriaPoints]);

  useEffect(() => {
    if (!formik.values.comorbidities.includes('Inne')) {
      setFieldValue('comorbiditiesOther', '')
    }
  }, [formik.values.comorbidities, setFieldValue])

  return {
    formik
  };
};
