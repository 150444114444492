import * as yup from "yup";

// hooks
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import {useCaseSettingsStore, useLzsrStore,} from "../../../../../../../../../store/hooks";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

// types
import {TLzsRinvoqStage02} from "./type";
import {EnAreaNames, EnFormNames,} from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  diagnosisDate: yup.string().required(),
  lzsCharacter: yup.string().required(),
  criteriaPsarc: yup
    .string()
    .nullable(),
  das28Result: yup
    .string()
    .nullable()
    .when("criteriaPsarc", {
      is: (value: string) => value === 'das 28',
      then: (schema) => schema.required(),
    }),
  result: yup.number().nullable()
    .when("criteriaPsarc", {
      is: (value: string) => value === 'das 28',
      then: (schema) => schema.required(),
    }),
  likertDoctor: yup.number().nullable()
    .when("criteriaPsarc", {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required(),
    }),
  likertPatient: yup.number().nullable()
    .when("criteriaPsarc", {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required(),
    }),
  generalAssessment: yup.number().nullable()
    .when("criteriaPsarc", {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required(),
    }),
  tenderJointsCount: yup.number().nullable().min(0).max(10).integer(),
  swollenJointsCount: yup.number().nullable().min(0).max(10).integer(),
  resultScaleOption: yup
    .string()
    .when("lzsCharacter", {
      is: (value: string) => value === 'postać obwodowa',
      then: (schema) => schema.required(),
    }),
  resultScale: yup.number().nullable().when("lzsCharacter", {
    is: (value: string) => value === 'postać obwodowa',
    then: (schema) => schema.required(),
  }),
  degreeOfBack: yup.number().nullable().when("lzsCharacter", {
    is: (value: string) => value === 'postać obwodowa',
    then: (schema) => schema.required(),
  }),
  generalAssessment2: yup.number().nullable().when("lzsCharacter", {
    is: (value: string) => value === 'postać obwodowa',
    then: (schema) => schema.required(),
  }),
  drugs: yup
    .array()
    .of(
      yup.object().shape({
        treatment: yup.string().required(),
        treatmentOther: yup.string().nullable(),
        gks: yup.string().required(),
        dose: yup.number().required(),
        unit: yup.string().required(),
        otherUnit: yup.string().max(16).when("unit", {
          is: (value: string) => value === "Inna jednostka",
          then: (schema) => schema.required(),
        }),
        doseRange: yup.number().required(),
      })
    )
    .min(1)
    .required(),
  relevantComment: yup
    .string().nullable()
});

const initialValues: TLzsRinvoqStage02 = {
  diagnosisDate: null,
  lzsCharacter: '',
  criteriaPsarc: '',
  das28Result: "",
  result: null,
  likertPatient: null,
  likertDoctor: null,
  generalAssessment: null,
  tenderJointsCount: null,
  swollenJointsCount: null,
  resultScaleOption: '',
  resultScale: null,
  degreeOfBack: null,
  generalAssessment2: null,
  drugs: [
    {
      treatment: "",
      treatmentOther: '',
      gks: "",
      dose: null,
      unit: "",
      otherUnit: "",
      doseRange: null,
    },
  ],
  relevantComment: ''
};

export const useStage02ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const lzsrStore = useLzsrStore();
  const {secondStage, caseStage} = lzsrStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(secondStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TLzsRinvoqStage02>({
    initialValues: {
      ...valuesObj,
      drugs: valuesObj.drugs?.length === 0 ? [{
        treatment: "",
        treatmentOther: '',
        gks: "",
        dose: null,
        unit: "",
        otherUnit: "",
        doseRange: null,
      }] : valuesObj.drugs
    }
    ,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`drugs[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const addDrug = () => {
    setFieldValue("drugs", [
      ...formik.values.drugs,
      {
        treatment: "",
        treatmentOther: '',
        gks: "",
        dose: null,
        unit: "",
        otherUnit: "",
        doseRange: null,
      },
    ]);
  };

  const deleteDrug = (index: number) => {
    const filteredItems = [
      ...formik.values.drugs.filter((el, idx) => idx !== index),
    ];
    setFieldValue("drugs", filteredItems);
  };

  const resetForAxial = () => {
    setFieldValue('resultScaleOption', '')
    setFieldValue('resultScale', null)
    setFieldValue('degreeOfBack', null)
    setFieldValue('generalAssessment2', null)
  }

  const resetForCircumferential = () => {
    setFieldValue('criteriaPsarc', '')
    setFieldValue('das28Result', '')
    setFieldValue('result', null)
    setFieldValue('likertPatient', null)
    setFieldValue('likertDoctor', null)
    setFieldValue('generalAssessment', null)
    setFieldValue('tenderJointsCount', null)
    setFieldValue('swollenJointsCount', null)
  }

  const resetForLikert = () => {
    setFieldValue('das28Result', '')
    setFieldValue('result', null)
  }
  const resetForDas = () => {
    setFieldValue('likertPatient', null)
    setFieldValue('likertDoctor', null)
  }

  return {
    formik,
    setOption,
    addDrug,
    deleteDrug,
    resetForAxial,
    resetForCircumferential,
    resetForDas,
    resetForLikert
  };
};
