import * as yup from "yup";
// hooks
import {useFormik} from "formik";
import {useCaseSettingsStore, useLzsrStore,} from "../../../../../../../../../store/hooks";
// types
import {TLzsRinvoqStage04} from "./type";
import {EnAreaNames, EnFormNames,} from "../../../../../../../../../store/caseSettingsStore";
import {useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

const createValidationSchema = (lzsCharacter: string) => {
  return yup.object().shape({
    drugInitiationDate: yup.string().required(),
    criteriaPsarc4: yup
      .string()
      .nullable(),
    das28Result4: yup.string().nullable().when('criteriaPsarc4', {
      is: (value: string) => value === 'das 28',
      then: (schema) => schema.required()
    }),
    result4: yup.number().nullable().when('criteriaPsarc4', {
      is: (value: string) => value === 'das 28',
      then: (schema) => schema.required()
    }),
    likertPatient4: yup.number().nullable().when('criteriaPsarc4', {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required()
    }),
    likertDoctor4: yup.number().nullable().when('criteriaPsarc4', {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required()
    }),
    generalAssessment4: yup.number().nullable().when('criteriaPsarc4', {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required()
    }),
    tenderJointsCount4: yup.number().nullable().integer().max(10).min(0),
    swollenJointsCount4: yup.number().nullable().integer().max(10).min(0),
    resultScaleOption4: yup.string().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać obwodowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    resultScale4: yup.number().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać obwodowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    degreeOfBack4: yup.number().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać obwodowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    generalAssessment24: yup.number().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać obwodowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    pasi4: yup.number().nullable(),
    observationIntervals: yup.string(),
    observations: yup
      .array()
      .of(
        yup.object().shape({
          monotherapy: yup.string().required(),
          combinationWithMedicine: yup
            .array()
            .of(
              yup.object().shape({
                combinationDrugName: yup.string().required(),
                otherCombinationDrug: yup.string().when("combinationDrugName", {
                  is: (value: string) => value === "Inne",
                  then: (schema) => schema.required(),
                }),
                combinationDose: yup.number().required(),
                combinationUnit: yup.string().required(),
                otherCombinationUnit: yup.string().when("combinationUnit", {
                  is: (value: string) => value === "Inna jednostka",
                  then: (schema) => schema.required(),
                }),
                combinationDuration: yup.number().required(),
              })
            )
            .when("monotherapy", {
              is: (value: string) => value === "kombinacja",
              then: (schema) => schema.min(1).required(),
            }),
          gks: yup.string().required(),
          criteriaPsarc: yup
            .string()
            .nullable(),
          das28Result: yup.string().nullable().when('criteriaPsarc', {
            is: (value: string) => value === 'das 28',
            then: (schema) => schema.required()
          }),
          result: yup.number().nullable().when('criteriaPsarc', {
            is: (value: string) => value === 'das 28',
            then: (schema) => schema.required()
          }),
          likertPatient: yup.number().nullable().when('criteriaPsarc', {
            is: (value: string) => value === 'likert',
            then: (schema) => schema.required()
          }),
          likertDoctor: yup.number().nullable().when('criteriaPsarc', {
            is: (value: string) => value === 'likert',
            then: (schema) => schema.required()
          }),
          generalAssessment: yup.number().nullable().when('criteriaPsarc', {
            is: (value: string) => value === 'likert',
            then: (schema) => schema.required()
          }),
          tenderJointsCount: yup.number().nullable().integer().max(10).min(0),
          swollenJointsCount: yup.number().nullable().integer().max(10).min(0),
          resultScaleOption: yup.string().nullable().test(
            function (value) {
              // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
              if (lzsCharacter === 'postać obwodowa') {
                return value !== null && value !== undefined;
              }
              // W przeciwnym wypadku wartość może być null
              return true;
            }
          ),
          resultScale: yup.number().nullable().test(
            function (value) {
              // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
              if (lzsCharacter === 'postać obwodowa') {
                return value !== null && value !== undefined;
              }
              // W przeciwnym wypadku wartość może być null
              return true;
            }
          ),
          degreeOfBack: yup.number().nullable().test(
            function (value) {
              // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
              if (lzsCharacter === 'postać obwodowa') {
                return value !== null && value !== undefined;
              }
              // W przeciwnym wypadku wartość może być null
              return true;
            }
          ),
          generalAssessment2: yup.number().nullable().test(
            function (value) {
              // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
              if (lzsCharacter === 'postać obwodowa') {
                return value !== null && value !== undefined;
              }
              // W przeciwnym wypadku wartość może być null
              return true;
            }
          ),
          pasi: yup.string().nullable()
        })
      )
      .when("observationIntervals", {
        is: (value: string) => value === "co 1 msc.",
        then: (schema) => schema.length(6).required(),
      })
      .when("observationIntervals", {
        is: (value: string) => value === "co 3 msc.",
        then: (schema) => schema.length(2).required(),
      })
      .when("observationIntervals", {
        is: (value: string) => value === "co 6 msc.",
        then: (schema) => schema.length(1).required(),
      }),
  });
}

const initialValues: TLzsRinvoqStage04 = {
  drugInitiationDate: null,
  criteriaPsarc4: '',
  das28Result4: '',
  result4: null,
  likertPatient4: null,
  likertDoctor4: null,
  generalAssessment4: null,
  tenderJointsCount4: null,
  swollenJointsCount4: null,
  resultScaleOption4: null,
  resultScale4: null,
  degreeOfBack4: null,
  generalAssessment24: null,
  pasi4: null,
  observationIntervals: "",
  observations: [],
};

export const useStage04ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // lzssStore
  const lzsrStore = useLzsrStore();
  const {fourthStage, caseStage, secondStage} = lzsrStore;

  // define API calls
  const caseApi = useCaseApi();
  const lzsCharacter = secondStage?.lzsCharacter || 'postać obwodowa'

  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TLzsRinvoqStage04>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: createValidationSchema(lzsCharacter),
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`observations[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `observations[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const observationObject = {
    monotherapy: "",
    combinationWithMedicine: [],
    gks: "",
    criteriaPsarc: '',
    das28Result: '',
    result: null,
    likertPatient: null,
    likertDoctor: null,
    generalAssessment: null,
    tenderJointsCount: null,
    swollenJointsCount: null,
    resultScaleOption: null,
    resultScale: null,
    degreeOfBack: null,
    generalAssessment2: null
  }

  const modifyObservations = (value: string) => {
    switch (value) {
      case "co 1 msc.":
        const additionalObservations = 6 - formik.values.observations.length;

        const additionalObjects = Array.from(
          {length: additionalObservations},
          () => JSON.parse(JSON.stringify(observationObject))
        );
        formik.setFieldValue("observations", [
          ...formik.values.observations,
          ...additionalObjects,
        ]);
        return;
      case "co 3 msc.":
        const additionalObservations3 = 2 - formik.values.observations.length;

        if (formik.values.observations.length >= 2) {
          const newObservations = [...formik.values.observations.slice(0, 2)];
          formik.setFieldValue("observations", [...newObservations]);
        } else {
          const additionalObjects3 = Array.from(
            {length: additionalObservations3},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          formik.setFieldValue("observations", [
            ...formik.values.observations,
            ...additionalObjects3,
          ]);
        }
        return;
      case "co 6 msc.":
        const additionalObservations6 = 1 - formik.values.observations.length;
        if (formik.values.observations.length >= 1) {
          const newObservations = [...formik.values.observations.slice(0, 1)];
          formik.setFieldValue("observations", [...newObservations]);
        } else {
          const additionalObjects6 = Array.from(
            {length: additionalObservations6},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          formik.setFieldValue("observations", [
            ...formik.values.observations,
            ...additionalObjects6,
          ]);
        }
        //formik.validateForm().then();
        return;
    }
  };

  const addCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.observations[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `observations[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      ...formik.values.observations[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };
  const resetForLikert = () => {
    setFieldValue('das28Result4', '')
    setFieldValue('result4', null)
  }
  const resetForDas = () => {
    setFieldValue('likertPatient4', null)
    setFieldValue('likertDoctor4', null)
  }

  const resetForLikertTreatments = (index: number) => {
    setFieldValue(`observations[${index}].das28Result`, '')
    setFieldValue(`observations[${index}].result`, null)
  }
  const resetForDasTreatments = (index: number) => {
    setFieldValue(`observations[${index}].likertPatient`, null)
    setFieldValue(`observations[${index}].likertDoctor`, null)
  }

  return {
    formik,
    setOption,
    modifyObservations,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
    lzsCharacter,
    resetForDas,
    resetForLikert,
    resetForLikertTreatments,
    resetForDasTreatments
  };
};
