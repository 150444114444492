import React from "react";
import {observer} from "mobx-react";
import {FormikErrors} from "formik";

// hooks
import {useStage03ViewModel} from "./Stage03.vm";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {Label} from "../../../../../../../../shared/ui/label/Label";
import {Input} from "../../../../../../../../shared/ui/input/Input";
import {RadioBox} from "../../../../../../../../shared/ui/radioBox/RadioBox";
import {DateInput} from "../../../../../../../../shared/ui/date/Date";
import {SingleSelect} from "../../../../../../../../shared/ui/singleSelect/SingleSelect";

// lib, helpers, styles
import theme from "../../../../../../../../../theme/theme";
import {combinationsOptions, ii23Options, il17Options, jakOptions, tnfOptions, variantOptions,} from "./Stage03.helper";
import {ICombinations, ITreatmentLines} from "./type";
import {ButtonAddDrug} from "../stage02/Stage02.styled";
import {RadioForm} from "../../../../../stages/firstStage/FirstStage.styled";

// icons
import {ReactComponent as Bin} from "../../../../../../../../../assets/icon/abbvieBin.svg";
import {ReactComponent as Plus} from "../../../../../../../../../assets/icon/abbviePlus.svg";
import {likertOptions, scaleOptions} from "../stage02/Stage02.helper";

export const Stage03 = observer(() => {
  const {
    formik,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    setCombinationOption,
    addOtherCombination,
    lzsCharacter,
    resetForDas,
    resetForLikert,
    resetForLikertTreatments,
    resetForDasTreatments
  } = useStage03ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Moment włączenia do programu lekowego
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data wejścia do programu lekowego</>}
          isUpperCase={true}
          name={"programEntryDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.programEntryDate}
          onClose={() => {
            formik.setFieldTouched("programEntryDate", true);
            formik.validateField("programEntryDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("programEntryDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("programEntryDate", true);
            setTimeout(() => {
              formik.validateField("programEntryDate");
            }, 0);
          }}
          isError={
            formik.touched.programEntryDate &&
            Boolean(formik.errors.programEntryDate)
          }
        />
      </Grid>
      {lzsCharacter === 'postać osiowa' && <>
        <Grid xs={12} item pb={2} display={"flex"} flexDirection={"column"} mt={2}>
          <Label
            size={"small"}
            isUpperCase={false}
            color={theme.colors.grey1}
            label={"KRYTERIA PsARC"}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.criteriaPsarc3 && Boolean(formik.errors.criteriaPsarc3)
              }
              id={"criteriaPsarc"}
              name={"criteriaPsarc3"}
              value={"das 28"}
              checked={formik.values.criteriaPsarc3 === "das 28"}
              onChange={(e) => {
                formik.handleChange(e)
                resetForDas()
              }}
            />
            <RadioBox
              isError={
                formik.touched.criteriaPsarc3 && Boolean(formik.errors.criteriaPsarc3)
              }
              id={"criteriaPsarc2"}
              name={"criteriaPsarc3"}
              value={"likert"}
              checked={formik.values.criteriaPsarc3 === "likert"}
              onChange={(e) => {
                formik.handleChange(e)
                resetForLikert()
              }}
            />
          </RadioForm>
        </Grid>
        {formik.values.criteriaPsarc3 === "das 28" && <>
          <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
            <Grid item pb={2} display={"flex"} flexDirection={"column"}>
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Wynik das 28"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    formik.touched.das28Result3 && Boolean(formik.errors.das28Result3)
                  }
                  id={"das28Result"}
                  name={"das28Result3"}
                  value={"crp"}
                  checked={formik.values.das28Result3 === "crp"}
                  onChange={formik.handleChange}
                />
                <RadioBox
                  isError={
                    formik.touched.das28Result3 && Boolean(formik.errors.das28Result3)
                  }
                  id={"das28Result2"}
                  name={"das28Result3"}
                  value={"ob"}
                  checked={formik.values.das28Result3 === "ob"}
                  onChange={formik.handleChange}
                />
              </RadioForm>
            </Grid>
            <Grid>
              <Input
                isRequired={true}
                label={"Wynik"}
                isUpperCase={true}
                name={"result3"}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue('result3', null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.result3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={formik.touched.result3 && Boolean(formik.errors.result3)}
              />
            </Grid>
            <Grid
              item
              justifyContent={"space-between"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Wynik skali"}
              />
              <Typography variant="caption" color={theme.colors.grey1}>
                &lt;2,6 - remisja <br/>
                &le;3,2 – aktywność mała <br/>
                &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                &gt;5,1 – aktywność duża
              </Typography>
            </Grid>
          </Grid></>}
        {formik.values.criteriaPsarc3 === 'likert' && <>
          <Grid container display={"flex"} gap={"20px"} mt={2}>
            <Grid item xs={5}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.likertPatient3 && Boolean(formik.errors.likertPatient3)
                }
                label={"Wynik w skali Likerta pacjent"}
                handleChange={(value) => setOption(value, "likertPatient3")}
                selectedValue={formik.values.likertPatient3 ?? ""}
                elementName={"likertPatient3"}
                options={likertOptions}
              />
            </Grid>
            <Grid>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.likertDoctor3 && Boolean(formik.errors.likertDoctor3)
                }
                label={"Wynik w skali Likerta lekarz"}
                handleChange={(value) => setOption(value, "likertDoctor3")}
                selectedValue={formik.values.likertDoctor3 ?? ""}
                elementName={"likertDoctor3"}
                options={likertOptions}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={5}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.generalAssessment3 && Boolean(formik.errors.generalAssessment3)
                }
                label={"OGÓLNA OCENA STANU CHOROBY"}
                handleChange={(value) => setOption(value, "generalAssessment3")}
                selectedValue={formik.values.generalAssessment3 ?? ""}
                elementName={"generalAssessment3"}
                options={scaleOptions}
              />
            </Grid>
          </Grid>
          <Grid container display={"flex"} gap={"20px"}>
            <Grid item xs={5}>
              <Input
                label={"Liczba bolesnych stawów"}
                isUpperCase={true}
                name={"tenderJointsCount3"}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue('tenderJointsCount3', null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.tenderJointsCount3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.tenderJointsCount3 &&
                  Boolean(formik.errors.tenderJointsCount3)
                }
              />
            </Grid>
            <Grid>
              <Input
                label={"Liczba obrzękniętych stawów"}
                isUpperCase={true}
                name={"swollenJointsCount3"}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue('swollenJointsCount3', null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.swollenJointsCount3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.swollenJointsCount3 &&
                  Boolean(formik.errors.swollenJointsCount3)
                }
              />
            </Grid>
          </Grid>
        </>}
      </>}
      {lzsCharacter === 'postać obwodowa' && <>
        <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
          <Grid item pb={2} display={"flex"} flexDirection={"column"}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Podaj wynik w skali"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.resultScaleOption3 && Boolean(formik.errors.resultScaleOption3)
                }
                id={"resultScaleOption"}
                name={"resultScaleOption3"}
                value={"basdai"}
                checked={formik.values.resultScaleOption3 === "basdai"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={
                  formik.touched.resultScaleOption3 && Boolean(formik.errors.resultScaleOption3)
                }
                id={"resultScaleOption2"}
                name={"resultScaleOption3"}
                value={"asdas"}
                checked={formik.values.resultScaleOption3 === "asdas"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
          <Grid>
            <Input
              isRequired={true}
              label={"Wynik"}
              isUpperCase={true}
              name={"resultScale3"}
              type={"number"}
              onChangeCustom={() => formik.setFieldValue('resultScale3', null)}
              maxWidth={"163px"}
              colorLabel={theme.colors.grey1}
              value={formik.values.resultScale3}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={formik.touched.resultScale3 && Boolean(formik.errors.resultScale3)}
            />
          </Grid>
        </Grid>
        <Grid container display={"flex"} gap={"20px"}>
          <Grid item xs={5}>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                formik.touched.degreeOfBack3 && Boolean(formik.errors.degreeOfBack3)
              }
              label={"Stopień bólu kręgosłupa"}
              handleChange={(value) => setOption(value, "degreeOfBack3")}
              selectedValue={formik.values.degreeOfBack3 ?? ""}
              elementName={"degreeOfBack3"}
              options={scaleOptions}
            />
          </Grid>
          <Grid>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                formik.touched.generalAssessment23 && Boolean(formik.errors.generalAssessment23)
              }
              label={"OGÓLNA OCENA STANU CHOROBY"}
              handleChange={(value) => setOption(value, "generalAssessment23")}
              selectedValue={formik.values.generalAssessment23 ?? ""}
              elementName={"generalAssessment23"}
              options={scaleOptions}
            />
          </Grid>
        </Grid>
      </>}
      <Grid item xs={12} mt={2}>
        <Input
          label={"wynik w skali pasi"}
          isUpperCase={true}
          name={"pasi"}
          type={"number"}
          onChangeCustom={() => formik.setFieldValue('pasi', null)}
          maxWidth={"163px"}
          colorLabel={theme.colors.grey1}
          value={formik.values.pasi}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          background={theme.colors.white}
          placeholder={"Wpisz"}
          isError={formik.touched.pasi && Boolean(formik.errors.pasi)}
        />
      </Grid>
      <Divider
        style={{width: "100%"}}
        variant={"fullWidth"}
        sx={{height: "2px", margin: "0 0 16px 0"}}
      />
      <Grid
        item
        xs={12}
        mb={2}
        mt={2}
        display={"flex"}
        justifyContent={"center"}
      >
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Linie leczenia
        </Typography>
      </Grid>
      <Grid container>
        {formik.values.treatmentLines?.map((line, index) => (
          <Grid
            key={index}
            item
            xs={12}
            mb={4}
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "0 16px",
              backgroundColor: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <Grid
              item
              xs={12}
              mt={2}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Linia leczenia {index + 1}
              </Typography>
              {formik.values.treatmentLines.length !== 1 && (
                <ButtonAddDrug type="button" onClick={() => deleteTreatment(index)}>
                  Usuń linię leczenia
                  <Bin/>
                </ButtonAddDrug>
              )}
            </Grid>
            <Grid item xs={12} mt={1}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  Array.isArray(formik.touched.treatmentLines) &&
                  formik.touched.treatmentLines[index]?.variants &&
                  Array.isArray(formik.errors.treatmentLines) &&
                  Boolean(
                    (
                      formik.errors.treatmentLines[
                        index
                        ] as FormikErrors<ITreatmentLines>
                    )?.variants
                  )
                }
                label={"Wybierz wariant"}
                handleChange={(value) => setOption(value, "variants", index)}
                selectedValue={
                  formik.values.treatmentLines[index]?.variants || ""
                }
                elementName={`treatmentLines[${index}].variants`}
                options={variantOptions}
              />
            </Grid>
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory TNF" && (
                <>
                  <Grid item xs={12} mt={1}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.tnfInhibitorType &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.tnfInhibitorType
                        )
                      }
                      label={"Rodzaj inhibitora TNF"}
                      handleChange={(value) =>
                        setOption(value, "tnfInhibitorType", index)
                      }
                      selectedValue={
                        formik.values.treatmentLines[index]?.tnfInhibitorType ||
                        ""
                      }
                      elementName={`treatmentLines[${index}].tnfInhibitorType`}
                      options={tnfOptions}
                    />
                  </Grid>
                </>
              )}

            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory IL-17" && (
                <>
                  <Grid item xs={12} mt={1}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.il17InhibitorType &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.il17InhibitorType
                        )
                      }
                      label={"Rodzaj inhibitora IL-17"}
                      handleChange={(value) =>
                        setOption(value, "il17InhibitorType", index)
                      }
                      selectedValue={
                        formik.values.treatmentLines[index]?.il17InhibitorType ||
                        ""
                      }
                      elementName={`treatmentLines[${index}].il17InhibitorType`}
                      options={il17Options}
                    />
                  </Grid>
                </>
              )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory JAK" && (
                <>
                  <Grid item xs={12} mt={1}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.jakInhibitorType &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.jakInhibitorType
                        )
                      }
                      label={"Rodzaj inhibitora JAK"}
                      handleChange={(value) =>
                        setOption(value, "jakInhibitorType", index)
                      }
                      selectedValue={
                        formik.values.treatmentLines[index]?.jakInhibitorType ||
                        ""
                      }
                      elementName={`treatmentLines[${index}].jakInhibitorType`}
                      options={jakOptions}
                    />
                  </Grid>
                </>
              )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory II-23" && (
                <>
                  <Grid item xs={12} mt={1}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.il23InhibitorType &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.il23InhibitorType
                        )
                      }
                      label={"Rodzaj inhibitora II-23"}
                      handleChange={(value) =>
                        setOption(value, "il23InhibitorType", index)
                      }
                      selectedValue={
                        formik.values.treatmentLines[index]?.il23InhibitorType ||
                        ""
                      }
                      elementName={`treatmentLines[${index}].il23InhibitorType`}
                      options={ii23Options}
                    />
                  </Grid>
                </>
              )}
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              pb={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Monoterapia czy kombinacja z innym lekiem?"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.monotherapy &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.monotherapy
                    )
                  }
                  id={`monotherapy1-${index}`}
                  name={`treatmentLines[${index}].monotherapy`}
                  value={"monoterapia"}
                  checked={
                    formik.values.treatmentLines[index].monotherapy ===
                    "monoterapia"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `treatmentLines[${index}].monotherapy`,
                      e.target.value
                    );
                    formik.setFieldValue(
                      `treatmentLines[${index}].combinationWithMedicine`,
                      []
                    );
                  }}
                />
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.monotherapy &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.monotherapy
                    )
                  }
                  id={`monotherapy2-${index}`}
                  name={`treatmentLines[${index}].monotherapy`}
                  value={"kombinacja"}
                  checked={
                    formik.values.treatmentLines[index].monotherapy ===
                    "kombinacja"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `treatmentLines[${index}].monotherapy`,
                      e.target.value
                    );
                    addCombination(index);
                  }}
                />
              </RadioForm>
            </Grid>
            {formik.values.treatmentLines &&
              formik.values.treatmentLines.length > 0 &&
              formik.values.treatmentLines[index]?.combinationWithMedicine
                .length > 0 &&
              formik.values.treatmentLines[index]?.combinationWithMedicine?.map(
                (combination, idx) => (
                  <Grid style={{backgroundColor: "rgba(7, 28, 71, 0.10)"}}>
                    <Grid key={idx} item xs={12} p={2} mb={2}>
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        justifyContent={"space-between"}
                        display={"flex"}
                      >
                        <Typography
                          variant={"h6"}
                          color={theme.colors.grey1}
                          textTransform={"uppercase"}
                          fontWeight={700}
                        >
                          Kombinacja {idx + 1}
                        </Typography>
                        {formik.values.treatmentLines[index]
                          .combinationWithMedicine?.length !== 1 && (
                          <ButtonAddDrug
                            type="button"
                            onClick={() => deleteCombination(index, idx)}
                          >
                            Usuń kombinację
                            <Bin/>
                          </ButtonAddDrug>
                        )}
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.treatmentLines) &&
                            Array.isArray(formik.errors.treatmentLines) &&
                            typeof formik.errors.treatmentLines[index] ===
                            "object" &&
                            formik.touched.treatmentLines[index]
                              ?.combinationWithMedicine?.[idx]
                              ?.combinationDrugName &&
                            Boolean(
                              (
                                (
                                  formik.errors.treatmentLines[
                                    index
                                    ] as FormikErrors<ITreatmentLines>
                                )?.combinationWithMedicine?.[
                                  idx
                                  ] as FormikErrors<ICombinations>
                              )?.combinationDrugName
                            )
                          }
                          label={"Kombinacja Z jakim lekiem?"}
                          handleChange={(value) =>
                            setCombinationOption(
                              value,
                              "combinationDrugName",
                              index,
                              idx
                            )
                          }
                          selectedValue={
                            formik.values.treatmentLines[index]
                              ?.combinationWithMedicine[idx]
                              .combinationDrugName || ""
                          }
                          elementName={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDrugName`}
                          options={combinationsOptions}
                        />
                      </Grid>
                      {formik.values.treatmentLines[index]
                          .combinationWithMedicine[idx].combinationDrugName ===
                        "Inne" && (
                          <Grid item xs={12}>
                            <Input
                              isRequired={true}
                              label={"nazwa innego leku*"}
                              isUpperCase={true}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationDrug`}
                              type={"text"}
                              colorLabel={theme.colors.grey1}
                              value={
                                formik.values.treatmentLines?.[index]
                                  ?.combinationWithMedicine[idx]
                                  .otherCombinationDrug || ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              borderColor={theme.colors.grey5}
                              placeholderColor={theme.colors.grey4}
                              background={theme.colors.white}
                              placeholder={"Wpisz"}
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.otherCombinationDrug &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.otherCombinationDrug
                                )
                              }
                            />
                          </Grid>
                        )}
                      <Grid
                        container
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"flex-end"}
                      >
                        <Grid>
                          <Input
                            isRequired={true}
                            label={"Dawka"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDose`}
                            type={"number"}
                            onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDose`, null)}
                            maxWidth={"163px"}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDose
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                              "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDose &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                      ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                    ] as FormikErrors<ICombinations>
                                )?.combinationDose
                              )
                            }
                          />
                        </Grid>
                        <Grid pb={2}>
                          <RadioForm>
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit1`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"g/m"}
                              isUpperCase={false}
                              checked={combination.combinationUnit === "g/m"}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value)
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`,
                                  '')
                              }}
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit2`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"mg/kg m.c."}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "mg/kg m.c."
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value)
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`,
                                  '')
                              }}
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit3`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"Inna jednostka"}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "Inna jednostka"
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                          </RadioForm>
                        </Grid>
                        {formik.values.treatmentLines[index]
                            .combinationWithMedicine[idx].combinationUnit ===
                          "Inna jednostka" && (
                            <Grid>
                              <Input
                                isRequired={true}
                                label={"Inna jednostka"}
                                isUpperCase={true}
                                name={`treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`}
                                type={"text"}
                                maxWidth={"163px"}
                                colorLabel={theme.colors.grey1}
                                value={
                                  formik.values.treatmentLines?.[index]
                                    ?.combinationWithMedicine[idx]
                                    .otherCombinationUnit || ""
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                borderColor={theme.colors.grey5}
                                placeholderColor={theme.colors.grey4}
                                background={theme.colors.white}
                                placeholder={"Wpisz"}
                                isError={
                                  Array.isArray(formik.touched.treatmentLines) &&
                                  Array.isArray(formik.errors.treatmentLines) &&
                                  typeof formik.errors.treatmentLines[index] ===
                                  "object" &&
                                  formik.touched.treatmentLines[index]
                                    ?.combinationWithMedicine?.[idx]
                                    ?.otherCombinationUnit &&
                                  Boolean(
                                    (
                                      (
                                        formik.errors.treatmentLines[
                                          index
                                          ] as FormikErrors<ITreatmentLines>
                                      )?.combinationWithMedicine?.[
                                        idx
                                        ] as FormikErrors<ICombinations>
                                    )?.otherCombinationUnit
                                  )
                                }
                              />
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Input
                            before={
                              <Label
                                isUpperCase={false}
                                style={{margin: "8px 0 0"}}
                                color={theme.colors.orange}
                                size={"superSmall"}
                                label={"liczba tygodni"}
                              />
                            }
                            maxWidth={"72px"}
                            isRequired={true}
                            label={"Zakres czasu przyjmowania"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDuration`}
                            type={"number"}
                            onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDuration`, null)}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDuration
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                              "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDuration &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                      ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                    ] as FormikErrors<ICombinations>
                                )?.combinationDuration
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}

            {formik.values.treatmentLines[index].monotherapy ===
              "kombinacja" && (
                <Grid
                  mt={4}
                  mb={2}
                  display={"flex"}
                  item
                  xs={12}
                  justifyContent={"center"}
                >
                  <ButtonAddDrug type="button" onClick={() => addOtherCombination(index)}>
                    Dodaj kombinację z lekiem
                    <Plus/>
                  </ButtonAddDrug>
                </Grid>
              )}

            <Divider
              style={{width: "100%"}}
              variant={"fullWidth"}
              sx={{height: "2px", margin: "0 0 16px 0"}}
            />
            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Glikokortykosteroidy (GKS)"}
                  isRequired={true}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.gks &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.gks
                      )
                    }
                    id={`gks11-${index}`}
                    name={`treatmentLines[${index}].gks`}
                    value={"tak"}
                    checked={
                      formik.values.treatmentLines[index]
                        .gks === "tak"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].gks`,
                        e.target.value
                      );
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.gks &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.gks
                      )
                    }
                    id={`gks22-${index}`}
                    name={`treatmentLines[${index}].gks`}
                    value={"nie"}
                    checked={
                      formik.values.treatmentLines[index]
                        .gks === "nie"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].gks`,
                        e.target.value
                      );
                    }}
                  />
                </RadioForm>
              </Grid>
            </Grid>
            {lzsCharacter === 'postać osiowa' && <>
              <Grid xs={12} item pb={2} display={"flex"} flexDirection={"column"} mt={2}>
                <Label
                  size={"small"}
                  isUpperCase={false}
                  color={theme.colors.grey1}
                  label={"KRYTERIA PsARC"}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.criteriaPsarc3followup &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.criteriaPsarc3followup
                      )
                    }
                    id={`criteriaPsarc3followup1-${index}`}
                    name={`treatmentLines[${index}].criteriaPsarc3followup`}
                    value={"das 28"}
                    checked={
                      formik.values.treatmentLines[index].criteriaPsarc3followup ===
                      "das 28"
                    }
                    onChange={(e) => {
                      formik.handleChange(e)
                      resetForDasTreatments(index)
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.criteriaPsarc3followup &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.criteriaPsarc3followup
                      )
                    }
                    id={`criteriaPsarc3followup2-${index}`}
                    name={`treatmentLines[${index}].criteriaPsarc3followup`}
                    value={"likert"}
                    checked={
                      formik.values.treatmentLines[index].criteriaPsarc3followup ===
                      "likert"
                    }
                    onChange={(e) => {
                      formik.handleChange(e)
                      resetForLikertTreatments(index)
                    }}
                  />
                </RadioForm>
              </Grid>
              {formik.values.treatmentLines[index].criteriaPsarc3followup === "das 28" && <>
                <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                  <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                    <Label
                      size={"small"}
                      isUpperCase={true}
                      color={theme.colors.grey1}
                      label={"Wynik das 28"}
                      isRequired={true}
                    />
                    <RadioForm>
                      <RadioBox
                        isError={
                          Array.isArray(formik.touched.treatmentLines) &&
                          formik.touched.treatmentLines[index]?.das28Result3followup &&
                          Array.isArray(formik.errors.treatmentLines) &&
                          Boolean(
                            (
                              formik.errors.treatmentLines[
                                index
                                ] as FormikErrors<ITreatmentLines>
                            )?.das28Result3followup
                          )
                        }
                        id={`das28Result3followup1-${index}`}
                        name={`treatmentLines[${index}].das28Result3followup`}
                        value={"crp"}
                        checked={
                          formik.values.treatmentLines[index].das28Result3followup ===
                          "crp"
                        }
                        onChange={formik.handleChange}
                      />
                      <RadioBox
                        isError={
                          Array.isArray(formik.touched.treatmentLines) &&
                          formik.touched.treatmentLines[index]?.das28Result3followup &&
                          Array.isArray(formik.errors.treatmentLines) &&
                          Boolean(
                            (
                              formik.errors.treatmentLines[
                                index
                                ] as FormikErrors<ITreatmentLines>
                            )?.das28Result3followup
                          )
                        }
                        id={`das28Result3followup2-${index}`}
                        name={`treatmentLines[${index}].das28Result3followup`}
                        value={"ob"}
                        checked={
                          formik.values.treatmentLines[index].das28Result3followup ===
                          "ob"
                        }
                        onChange={formik.handleChange}
                      />
                    </RadioForm>
                  </Grid>
                  <Grid>
                    <Input
                      isRequired={true}
                      label={"Wynik"}
                      isUpperCase={true}
                      name={`treatmentLines[${index}].result3followup`}
                      type={"number"}
                      onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].result3followup`, null)}
                      maxWidth={"163px"}
                      colorLabel={theme.colors.grey1}
                      value={
                        formik.values.treatmentLines?.[index]?.result3followup
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz"}
                      isError={
                        formik.touched.treatmentLines?.[index]?.result3followup &&
                        Boolean(
                          (
                            formik.errors.treatmentLines?.[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.result3followup
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    justifyContent={"space-between"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Label
                      size={"small"}
                      isUpperCase={true}
                      color={theme.colors.grey1}
                      label={"Wynik skali"}
                    />
                    <Typography variant="caption" color={theme.colors.grey1}>
                      &lt;2,6 - remisja <br/>
                      &le;3,2 – aktywność mała <br/>
                      &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                      &gt;5,1 – aktywność duża
                    </Typography>
                  </Grid>
                </Grid></>}
              {formik.values.treatmentLines[index].criteriaPsarc3followup === 'likert' && <>
                <Grid container display={"flex"} gap={"20px"} mt={2}>
                  <Grid item xs={5}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.likertPatient3followup &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.likertPatient3followup
                        )
                      }
                      label={"Wynik w skali Likerta pacjent"}
                      handleChange={(value) => setOption(value, "likertPatient3followup", index)}
                      selectedValue={
                        formik.values.treatmentLines[index]?.likertPatient3followup ?? ""
                      }
                      elementName={`treatmentLines[${index}].likertPatient3followup`}
                      options={likertOptions}
                    />
                  </Grid>
                  <Grid>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.likertDoctor3followup &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.likertDoctor3followup
                        )
                      }
                      label={"Wynik w skali Likerta lekarz"}
                      handleChange={(value) => setOption(value, "likertDoctor3followup", index)}
                      selectedValue={
                        formik.values.treatmentLines[index]?.likertDoctor3followup ?? ""
                      }
                      elementName={`treatmentLines[${index}].likertDoctor3followup`}
                      options={likertOptions}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={5}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.generalAssessment3followup &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.generalAssessment3followup
                        )
                      }
                      label={"OGÓLNA OCENA STANU CHOROBY"}
                      handleChange={(value) => setOption(value, "generalAssessment3followup", index)}
                      selectedValue={
                        formik.values.treatmentLines[index]?.generalAssessment3followup ?? ""
                      }
                      elementName={`treatmentLines[${index}].generalAssessment3followup`}
                      options={scaleOptions}
                    />
                  </Grid>
                </Grid>
                <Grid container display={"flex"} gap={"20px"}>
                  <Grid item xs={5}>
                    <Input
                      label={"Liczba bolesnych stawów"}
                      isUpperCase={true}
                      name={`treatmentLines[${index}].tenderJointsCount3followup`}
                      type={"number"}
                      onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].tenderJointsCount3followup`, null)}
                      maxWidth={"163px"}
                      colorLabel={theme.colors.grey1}
                      value={
                        formik.values.treatmentLines?.[index]?.tenderJointsCount3followup
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz"}
                      isError={
                        formik.touched.treatmentLines?.[index]?.tenderJointsCount3followup &&
                        Boolean(
                          (
                            formik.errors.treatmentLines?.[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.tenderJointsCount3followup
                        )
                      }
                    />
                  </Grid>
                  <Grid>
                    <Input
                      label={"Liczba obrzękniętych stawów"}
                      isUpperCase={true}
                      name={`treatmentLines[${index}].swollenJointsCount3followup`}
                      type={"number"}
                      onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].swollenJointsCount3followup`, null)}
                      maxWidth={"163px"}
                      colorLabel={theme.colors.grey1}
                      value={
                        formik.values.treatmentLines?.[index]?.swollenJointsCount3followup
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz"}
                      isError={
                        formik.touched.treatmentLines?.[index]?.swollenJointsCount3followup &&
                        Boolean(
                          (
                            formik.errors.treatmentLines?.[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.swollenJointsCount3followup
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>}
            </>}
            {lzsCharacter === 'postać obwodowa' && <>
              <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Podaj wynik w skali"}
                    isRequired={true}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]
                          ?.resultScaleOption3followup &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.resultScaleOption3followup
                        )
                      }
                      id={`resultScaleOption3followup2-${index}`}
                      name={`treatmentLines[${index}].resultScaleOption3followup`}
                      value={"basdai"}
                      checked={
                        formik.values.treatmentLines[index]
                          .resultScaleOption3followup === "basdai"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `treatmentLines[${index}].resultScaleOption3followup`,
                          e.target.value
                        );
                      }}
                    />
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]
                          ?.resultScaleOption3followup &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.resultScaleOption3followup
                        )
                      }
                      id={`resultScaleOption3followup22-${index}`}
                      name={`treatmentLines[${index}].resultScaleOption3followup`}
                      value={"asdas"}
                      checked={
                        formik.values.treatmentLines[index]
                          .resultScaleOption3followup === "asdas"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `treatmentLines[${index}].resultScaleOption3followup`,
                          e.target.value
                        );
                      }}
                    />
                  </RadioForm>
                </Grid>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"Wynik"}
                    isUpperCase={true}
                    name={`treatmentLines[${index}].resultScale3followup`}
                    type={"number"}
                    onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].resultScale3followup`, null)}
                    maxWidth={"163px"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.treatmentLines?.[index]?.resultScale3followup
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]?.resultScale3followup &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.resultScale3followup
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"}>
                <Grid item xs={5}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.degreeOfBack3followup &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.degreeOfBack3followup
                      )
                    }
                    label={"Stopień bólu kręgosłupa"}
                    handleChange={(value) => setOption(value, "degreeOfBack3followup", index)}
                    selectedValue={
                      formik.values.treatmentLines[index]?.degreeOfBack3followup ?? ""
                    }
                    elementName={`treatmentLines[${index}].degreeOfBack3followup`}
                    options={scaleOptions}
                  />
                </Grid>
                <Grid>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.generalAssessment23followup &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.generalAssessment23followup
                      )
                    }
                    label={"OGÓLNA OCENA STANU CHOROBY"}
                    handleChange={(value) => setOption(value, "generalAssessment23followup", index)}

                    selectedValue={
                      formik.values.treatmentLines[index]?.generalAssessment23followup ?? ""
                    }
                    elementName={`treatmentLines[${index}].generalAssessment23followup`}
                    options={scaleOptions}
                  />
                </Grid>
              </Grid>
            </>}
            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Czy były objawy nietolerancji wybranego leku?"}
                  isRequired={true}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.symptomsOfIntolerance &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.symptomsOfIntolerance
                      )
                    }
                    id={`symptomsOfIntolerance1-${index}`}
                    name={`treatmentLines[${index}].symptomsOfIntolerance`}
                    value={"tak"}
                    checked={
                      formik.values.treatmentLines[index]
                        .symptomsOfIntolerance === "tak"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].symptomsOfIntolerance`,
                        e.target.value
                      );
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.symptomsOfIntolerance &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.symptomsOfIntolerance
                      )
                    }
                    id={`symptomsOfIntolerance2-${index}`}
                    name={`treatmentLines[${index}].symptomsOfIntolerance`}
                    value={"nie"}
                    checked={
                      formik.values.treatmentLines[index]
                        .symptomsOfIntolerance === "nie"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].symptomsOfIntolerance`,
                        e.target.value
                      );
                    }}
                  />
                </RadioForm>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid mt={1} display={"flex"} item xs={12} justifyContent={"center"}>
        <ButtonAddDrug type="button" onClick={addLine}>
          Dodaj linię leczenia
          <Plus/>
        </ButtonAddDrug>
      </Grid>
    </Grid>
  );
});
