import { ICase } from "../../../../interfaces/admin/cases/cases";
import { CaseStatus } from "../../../../interfaces/user/draftCase/draft";

export const filterCases = (
  cases: ICase[] | null,
  selectedStatus: CaseStatus | "",
  selectedForm: number | ""
) => {
  if (cases === null) return [];

  let newCases = [...cases];

  if (selectedStatus !== "") {
    newCases = newCases.filter(
      (caseElement) => caseElement.status === selectedStatus
    );
  }
  if (selectedForm !== "") {
    newCases = newCases.filter(
      (caseElement) => caseElement.form.id === +selectedForm
    );
  }
  return newCases;
};
