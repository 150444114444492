import { COINFECTIONTYPE } from "../../../../constants/coinfection";
import { CaseStatus } from "../../../../interfaces/user/draftCase/draft";

type Statuses<T extends string> = Partial<{
  [K in T]: {
    id: number;
    title: string;
    value?: any;
  };
}>;

export type StatusesCase = Statuses<CaseStatus>;

export enum CoinfactionStatus {
  ALL = "ALL",
  WITH_COINFECTIONS = "WITH_COINFECTIONS",
  WITHOUT_COINFECTIONS = "WITHOUT_COINFECTIONS",
}

export const statusesSettings: StatusesCase = {
  [CaseStatus.ACCEPT]: {
    id: 1,
    title: "Zaakceptowany",
  },
  [CaseStatus.REJECT]: {
    id: 2,
    title: "Do edycji",
  },
  [CaseStatus.DRAFT]: {
    id: 3,
    title: "Szkic",
  },
  [CaseStatus.SENT]: {
    id: 4,
    title: "Oczekujący",
  }
};

export const coinfectionsSettings: Statuses<CoinfactionStatus> = {
  [CoinfactionStatus.ALL]: {
    id: 0,
    title: "Wszystko",
    value: [
      COINFECTIONTYPE.NOCOINFECTION,
      COINFECTIONTYPE.HIVHCV,
      COINFECTIONTYPE.HBVHCV,
      COINFECTIONTYPE.HIVHBHCV,
    ],
  },
  [CoinfactionStatus.WITHOUT_COINFECTIONS]: {
    id: 1,
    title: "Bez koinfekcji",
    value: [COINFECTIONTYPE.NOCOINFECTION],
  },
  [CoinfactionStatus.WITH_COINFECTIONS]: {
    id: 2,
    title: "Z koinfekcjami",
    value: [
      COINFECTIONTYPE.HIVHCV,
      COINFECTIONTYPE.HBVHCV,
      COINFECTIONTYPE.HIVHBHCV,
    ],
  },
};
