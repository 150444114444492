import {observer} from "mobx-react";

// hooks
import {useZzskStore} from "../../../../../../../../store/hooks";

// components
import {Grid} from "@mui/material";
import {GroupForm, Label, NormalText} from "../../../virusology/hcv/HcvViewCase.styled";
import {Attachments} from "../../../virusology/hcv/common/attachments/Attachments";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";

export const Observations = observer(() => {
  const caseStore = useZzskStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Rinvoq:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Label>Wyniki badań przed włączeniem leku Rinvoq</Label>
          <Grid item xs={12} mb={4} mt={2}>
            <Label>Data włączenia leku</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.drugInitiationDate))}
            </NormalText>
          </Grid>
          <Grid container gap={'20px'} mb={4}>
            <Grid item xs={3}>
              <Label>podaj wynik w skali</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.resultScaleOption4)}
              </NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>WYNIK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.result4)}</NormalText>
            </Grid>
          </Grid>
          <Grid container gap={'20px'}>
            <Grid item xs={2}>
              <Label>WYNIK VAS</Label>
              <NormalText>{checkValueIsEmpty(caseElement.vasResult4)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>WYNIK CRP</Label>
              <NormalText>{checkValueIsEmpty(caseElement.crpResult4)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>WYNIK OB</Label>
              <NormalText>{checkValueIsEmpty(caseElement.obResult4)}</NormalText>
            </Grid>
          </Grid>
        </Grid>
        <Grid container bgcolor={"white"} p={2} mt={5}>
          <Label>Obserwacja na leku Rinvoq</Label>
          <Grid item xs={12} mt={2}>
            <Label>Interwały obserwacji</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.observationIntervals)}
            </NormalText>
          </Grid>
        </Grid>
        {caseElement.observations.map((observation, index) => (
          <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
            <Grid item xs={12} mb={2}>
              <Label $size={"large"} $mb={0}>
                {`Obserwacja ${index + 1}`}
              </Label>
            </Grid>

            <Grid container gap={'20px'} mb={4}>
              <Grid item xs={12} mt={4}>
                <Label>Glikokortykosteroidy (GKS)</Label>
                <NormalText>{checkValueIsEmpty(observation.gks)}</NormalText>
              </Grid>
              <Grid item xs={3}>
                <Label>podaj wynik w skali</Label>
                <NormalText>
                  {checkValueIsEmpty(observation.resultScaleOption)}
                </NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(observation.result)}</NormalText>
              </Grid>
            </Grid>
            <Grid container gap={'20px'}>
              <Grid item xs={2}>
                <Label>WYNIK VAS</Label>
                <NormalText>{checkValueIsEmpty(observation.vasResult)}</NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK CRP</Label>
                <NormalText>{checkValueIsEmpty(observation.crpResult)}</NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK OB</Label>
                <NormalText>{checkValueIsEmpty(observation.obResult)}</NormalText>
              </Grid>
            </Grid>
          </Grid>))}
      </Grid>
      <Grid container mt={8} mb={5}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Wnioski:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12}>
            <NormalText>
              {checkValueIsEmpty(caseElement.conclusions)}
            </NormalText>
          </Grid>
        </Grid>
        {caseElement.files && caseElement.files.length > 0 &&
          <Grid container bgcolor={"white"} p={2} mt={3}>
            <Grid item xs={12}>
              <Attachments
                title={'Zdjęcia przykładowych zmian skórnych lub obrazowania'}
                attachments={
                  caseElement.files
                }
              />
            </Grid>
          </Grid>}
      </Grid>
    </>
  );
});
