import React from "react";
import {FormikProvider} from "formik";
import {observer} from "mobx-react-lite";

// hooks
import {useStage02ViewModel} from "./Stage02.vm";

// components
import {Grid} from "@mui/material";
import {DateInput} from "../../../../../../../../shared/ui/date/Date";
import {ButtonContainer, CheckboxGroup, Paragraph, Subtitle,} from "./Stage02.styled";
import {RadioForm} from "../../../../../stages/firstStage/FirstStage.styled";
import {RadioBox} from "../../../../../../../../shared/ui/radioBox/RadioBox";
import {Input} from "../../../../../../../../shared/ui/input/Input";
import {Label} from "../../../../../../../../shared/ui/label/Label";
import {SingleSelect} from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import {
  MultipleFileUpload
} from "../../../../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import {FileSource} from "../../../../../../../../shared/uploadFiles/FileUploadLayout";
import {Checkbox} from "../../../../../../../../shared/ui/checkbox/Checkbox";
import {ReactComponent as Plus} from "../../../../../../../../../assets/icon/abbviePlus.svg";

// types
import {IDrug, THcvStage02} from "./type";

// lib
import {
  genotypeSelect,
  metavirSelect,
  placesOfDetectionSelect,
  previouslyTreatedCountSelect,
  rnaUnitSelect,
} from "./stageSettings";
import theme from "../../../../../../../../../theme/theme";
import {DrugElement} from "./drug/Drug";
import {ButtonTransparent} from "../../../../../saveDraftBtn/SaveDraftBtn.styled";

export const Stage02 = observer(() => {
  const {
    formik,
    extraChangeOptionPreviouslyTreatedCount,
    abdominalUltrasoundFiles,
    addAbdominalUltrasoundFile,
    removeAbdominalUltrasoundFile,
    addLiverFile,
    removeLiverFile,
    liverBiopsyFiles,
    addDrug,
    deleteDrug,
    handleCheckbox,
    clearFields,
    potentialDrugInteractionFiles,
    addDrugInteractionFile,
    removeDrugInteractionFile,
    coInfection,
  } = useStage02ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      justifyContent={"flex-end"}
      display={"flex"}
      margin={"0 auto"}
      mt={7}
      mb={5}
    >
      <Grid item xs={12} alignSelf={"center"}>
        <Grid container>
          <Grid item xs={5}>
            <DateInput
              openTo={"year"}
              inputFormat={"yyyy"}
              views={["year"]}
              isRequired={true}
              maxWidth={"254px"}
              label={<>Data wykrycia zakażenia</>}
              isUpperCase={true}
              name={"dateOfDetection"}
              size={"small"}
              color={theme.colors.grey1}
              value={formik.values.dateOfDetection}
              onClose={() => {
                formik.setFieldTouched("dateOfDetection", true);
                formik.validateField("dateOfDetection");
              }}
              onChange={(e, context) => {
                formik.setFieldValue("dateOfDetection", e);
              }}
              onBlur={() => {
                formik.setFieldTouched("dateOfDetection", true);
                setTimeout(() => {
                  formik.validateField("dateOfDetection");
                }, 0);
              }}
              isError={
                formik.touched.dateOfDetection &&
                Boolean(formik.errors.dateOfDetection)
              }
            />
          </Grid>
          <Grid item xs={7}>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz miejsce"}
              isError={
                formik.touched.placeOfDetection &&
                Boolean(formik.errors.placeOfDetection)
              }
              label={"Miejsce wykrycia"}
              handleChange={(value) =>
                formik.setFieldValue("placeOfDetection", value)
              }
              selectedValue={
                placesOfDetectionSelect.find(
                  (el) => el.id === formik.values.placeOfDetection
                )?.title ?? ""
              }
              elementName={"placeOfDetection"}
              options={placesOfDetectionSelect}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"droga zakażenia"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.routeOfInfection &&
                Boolean(formik.errors.routeOfInfection)
              }
              id={"routeOfInfection1"}
              name={"routeOfInfection"}
              value={"nieznana droga zakażenia"}
              checked={
                formik.values.routeOfInfection === "nieznana droga zakażenia"
              }
              onChange={(e) =>
                clearFields<THcvStage02>(
                  formik,
                  e,
                  "nieznana droga zakażenia",
                  {name: "routeOfInfectionDesc"}
                )
              }
            />
            <RadioBox
              isError={
                formik.touched.routeOfInfection &&
                Boolean(formik.errors.routeOfInfection)
              }
              id={"routeOfInfection2"}
              name={"routeOfInfection"}
              value={"znana droga zakażenia"}
              checked={
                formik.values.routeOfInfection === "znana droga zakażenia"
              }
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        {formik.values.routeOfInfection === "znana droga zakażenia" && (
          <Grid item xs={12} mt={1}>
            <Input
              isRequired={true}
              maxWidth={"100%"}
              width={"100%"}
              isUpperCase={true}
              name={"routeOfInfectionDesc"}
              type={"textarea"}
              colorLabel={theme.colors.grey1}
              value={formik.values.routeOfInfectionDesc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz najbardziej prawdopodobną drogę zakażenia..."}
              isError={
                formik.touched.routeOfInfectionDesc &&
                Boolean(formik.errors.routeOfInfectionDesc)
              }
            />
          </Grid>
        )}
        <Subtitle>zaawansowanie choroby wątroby w momencie wykrycia</Subtitle>
        <Grid item xs={12}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"przeprowadzone badanie"}
            isRequired={true}
          />
        </Grid>
        <Grid>
          <RadioForm>
            <Grid item xs={6}>
              <RadioBox
                isError={
                  formik.touched.scaleScore && Boolean(formik.errors.scaleScore)
                }
                id={"scaleScore1"}
                name={"scaleScore"}
                value={"wynik w skali METAVIR"}
                checked={formik.values.scaleScore === "wynik w skali METAVIR"}
                onChange={(e) =>
                  clearFields<THcvStage02>(formik, e, "wynik w skali METAVIR", {
                    name: "apriDesc",
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <RadioBox
                isError={
                  formik.touched.scaleScore && Boolean(formik.errors.scaleScore)
                }
                id={"scaleScore2"}
                name={"scaleScore"}
                value={"APRI lub FIB-4"}
                checked={formik.values.scaleScore === "APRI lub FIB-4"}
                onChange={(e) =>
                  clearFields<THcvStage02>(formik, e, "APRI lub FIB-4", {
                    name: "metavirDesc",
                  })
                }
              />
            </Grid>
          </RadioForm>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            flexWrap={"nowrap"}
          >
            <Grid item xs={6}>
              <SingleSelect
                disabled={
                  !(formik.values.scaleScore === "wynik w skali METAVIR")
                }
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.metavirDesc &&
                  Boolean(formik.errors.metavirDesc)
                }
                label={""}
                handleChange={(value) =>
                  formik.setFieldValue("metavirDesc", value)
                }
                selectedValue={formik.values.metavirDesc}
                elementName={"metavirDesc"}
                options={metavirSelect}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                disabled={!(formik.values.scaleScore === "APRI lub FIB-4")}
                width={"100%"}
                isUpperCase={true}
                name={"apriDesc"}
                type={"text"}
                colorLabel={theme.colors.grey1}
                value={formik.values.apriDesc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={
                  formik.touched.apriDesc && Boolean(formik.errors.apriDesc)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {(formik.values.metavirDesc === "F3-F4" ||
          formik.values.metavirDesc === "F4") && (
          <Grid item xs={12} mt={2}>
            <Input
              maxWidth={"100%"}
              width={"100%"}
              isUpperCase={true}
              name={"clinicalSignsOfCirrhosis"}
              type={"textarea"}
              label={"Kliniczne objawy marskości"}
              colorLabel={theme.colors.grey1}
              value={formik.values.clinicalSignsOfCirrhosis}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={
                formik.touched.clinicalSignsOfCirrhosis &&
                Boolean(formik.errors.clinicalSignsOfCirrhosis)
              }
            />
          </Grid>
        )}
        <Grid item xs={12} mt={2}>
          <Input
            maxWidth={"100%"}
            width={"100%"}
            isUpperCase={true}
            name={"extrahepaticManifestationsOfHCV"}
            type={"textarea"}
            label={"pozawątrobowe manifestacje HCV"}
            colorLabel={theme.colors.grey1}
            value={formik.values.extrahepaticManifestationsOfHCV}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={
              formik.touched.extrahepaticManifestationsOfHCV &&
              Boolean(formik.errors.extrahepaticManifestationsOfHCV)
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          display={"flex"}
          flexWrap={"nowrap"}
          alignItems={"flex-end"}
          gap={1}
        >
          <Grid item xs={9}>
            <Input
              maxWidth={"100%"}
              width={"100%"}
              name={"hcvRna"}
              type={"text"}
              label={"OZNACZENIE HCV RNA METODĄ ILOŚCIOWĄ [IU/mL, kopii/ml]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.hcvRna}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.hcvRna && Boolean(formik.errors.hcvRna)}
            />
          </Grid>

          <Grid item xs={3}>
            <SingleSelect
              disabled={!formik.values.hcvRna}
              isRequired={true}
              defaultValue={"Wybierz jednostkę"}
              isError={
                formik.touched.hcvRnaUnit && Boolean(formik.errors.hcvRnaUnit)
              }
              label={""}
              handleChange={(value) =>
                formik.setFieldValue("hcvRnaUnit", value)
              }
              selectedValue={formik.values.hcvRnaUnit}
              elementName={"hcvRnaUnit"}
              options={rnaUnitSelect}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Label
            size={"small"}
            isRequired={true}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"morfologia krwi"}
          />
        </Grid>
        <Grid container gap={"20px"} flexWrap={"nowrap"} mt={2}>
          <Grid item xs={3}>
            <Input
              name={"rbc"}
              type={"text"}
              label={"RBC [mln/µl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.rbc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.rbc && Boolean(formik.errors.rbc)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"hgb"}
              type={"text"}
              label={"HGB [g/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.hgb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.hgb && Boolean(formik.errors.hgb)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"htc"}
              type={"text"}
              label={"HTC [%]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.htc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.htc && Boolean(formik.errors.htc)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"plt"}
              type={"text"}
              label={"PLT [tys/µl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.plt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.plt && Boolean(formik.errors.plt)}
            />
          </Grid>
        </Grid>
        <Grid container gap={"20px"} flexWrap={"nowrap"}>
          <Grid item xs={3}>
            <Input
              name={"wbc"}
              type={"text"}
              label={"WBC [tys/µl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.wbc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.wbc && Boolean(formik.errors.wbc)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"mcv"}
              type={"text"}
              label={"MCV [fl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.mcv}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.mcv && Boolean(formik.errors.mcv)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"mch"}
              type={"text"}
              label={"MCH [pg]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.mch}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.mch && Boolean(formik.errors.mch)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"mchc"}
              type={"text"}
              label={"MCHC [g/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.mchc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.mchc && Boolean(formik.errors.mchc)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          gap={"20px"}
          flexWrap={"nowrap"}
          mt={2}
        >
          <Grid item xs={6}>
            <Input
              name={"aiat"}
              type={"text"}
              isRequired={true}
              label={"OZNACZENIE AKTYWNOŚCI AIAT [U/l]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.aiat}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.aiat && Boolean(formik.errors.aiat)}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              isRequired={true}
              name={"albumin"}
              type={"text"}
              label={"OZNACZENIE STĘŻENIA ALBUMINY [g/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.albumin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.albumin && Boolean(formik.errors.albumin)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          gap={"20px"}
          flexWrap={"nowrap"}
          mt={2}
        >
          <Grid item xs={6}>
            <Input
              name={"bilirubin"}
              type={"text"}
              isRequired={true}
              label={"OZNACZENIE STĘŻENIA BILIRUBINY [mg/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.bilirubin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={
                formik.touched.bilirubin && Boolean(formik.errors.bilirubin)
              }
            />
          </Grid>
          <Grid item xs={6} flexWrap={"nowrap"}>
            <Grid item xs={12}>
              <Label
                size={"small"}
                isUpperCase={true}
                isRequired={true}
                color={theme.colors.grey1}
                label={"czas lub wskaźnik protrombinowy"}
              />
            </Grid>
            <Grid container flexWrap={"nowrap"} gap={"20px"}>
              <Grid item xs={6}>
                <Input
                  isRequired={true}
                  maxWidth={"164px"}
                  name={"prothrombin"}
                  type={"text"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.prothrombin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={
                    formik.touched.prothrombin &&
                    Boolean(formik.errors.prothrombin)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <RadioForm>
                  <RadioBox
                    isError={
                      formik.touched.prothrombinUnit &&
                      Boolean(formik.errors.prothrombinUnit)
                    }
                    id={"prothrombinUnit1"}
                    name={"prothrombinUnit"}
                    value={"PT [sek.]"}
                    checked={formik.values.prothrombinUnit === "PT [sek.]"}
                    onChange={formik.handleChange}
                  />
                  <RadioBox
                    isError={
                      formik.touched.prothrombinUnit &&
                      Boolean(formik.errors.prothrombinUnit)
                    }
                    id={"prothrombinUnit2"}
                    name={"prothrombinUnit"}
                    value={"INR"}
                    checked={formik.values.prothrombinUnit === "INR"}
                    onChange={formik.handleChange}
                  />
                </RadioForm>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          gap={"20px"}
          flexWrap={"nowrap"}
          mt={2}
        >
          <Grid item xs={6}>
            <Input
              name={"creatinine"}
              type={"text"}
              isRequired={true}
              label={"OZNACZENIE POZIOMU KREATYNINY [mg/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.creatinine}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={
                formik.touched.creatinine && Boolean(formik.errors.creatinine)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name={"egfr"}
              type={"text"}
              isRequired={true}
              label={"OZNACZENIE POZIOMU eGFR [ml/min/1,73m"}
              supLabel={
                <>
                  <sup>2</sup>]
                </>
              }
              colorLabel={theme.colors.grey1}
              value={formik.values.egfr}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.egfr && Boolean(formik.errors.egfr)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Label
            size={"small"}
            isUpperCase={true}
            isRequired={true}
            color={theme.colors.grey1}
            label={
              "oznaczenie w kierunku zakażenia hiv (przeciwciała anty-hiv/antygen p24)"
            }
          />
        </Grid>
        <Grid item xs={12}>
          <RadioForm>
            <RadioBox
              isError={formik.touched.antiHiv && Boolean(formik.errors.antiHiv)}
              id={"antiHiv1"}
              name={"antiHiv"}
              value={"niereaktywny"}
              checked={formik.values.antiHiv === "niereaktywny"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.antiHiv && Boolean(formik.errors.antiHiv)}
              id={"antiHiv2"}
              name={"antiHiv"}
              value={"niejednoznaczny"}
              checked={formik.values.antiHiv === "niejednoznaczny"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.antiHiv && Boolean(formik.errors.antiHiv)}
              id={"antiHiv3"}
              name={"antiHiv"}
              value={"reaktywny"}
              checked={formik.values.antiHiv === "reaktywny"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            isRequired={true}
            label={"oznaczenie antygenu hbs"}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioForm>
            <RadioBox
              isError={formik.touched.hbs && Boolean(formik.errors.hbs)}
              id={"hbs1"}
              name={"hbs"}
              value={"niereaktywny"}
              checked={formik.values.hbs === "niereaktywny"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.hbs && Boolean(formik.errors.hbs)}
              id={"hbs2"}
              name={"hbs"}
              value={"niejednoznaczny"}
              checked={formik.values.hbs === "niejednoznaczny"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.hbs && Boolean(formik.errors.hbs)}
              id={"hbs3"}
              name={"hbs"}
              value={"reaktywny"}
              checked={formik.values.hbs === "reaktywny"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={
              "USG jamy brzusznej lub inne badanie obrazowe istotne z punktu widzenia terapeutycznego"
            }
          />
          <Paragraph>
            Jeśli nie było wykonywane w okresie ostatnich 6 miesięcy
          </Paragraph>
          <Input
            name={"abdominalUltrasound"}
            type={"textarea"}
            isUpperCase={true}
            colorLabel={theme.colors.grey1}
            value={formik.values.abdominalUltrasound}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={
              formik.touched.abdominalUltrasound &&
              Boolean(formik.errors.abdominalUltrasound)
            }
          />
          <Paragraph>Proszę nie umieszczać zdjęcia z opisem badania</Paragraph>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            fontWeight={"700"}
            id={"abdominalUltrasoundCheckbox"}
            name={"abdominalUltrasoundCheckbox"}
            fontSize={"12px"}
            textColor={theme.colors.abbvie}
            label={
              "Oświadczam, że na załączonych zdjęciach nie znajdują sie dane pacjenta*"
            }
            checked={formik.values.abdominalUltrasoundCheckbox}
            onChange={() =>
              formik.setFieldValue(
                "abdominalUltrasoundCheckbox",
                !formik.values.abdominalUltrasoundCheckbox
              )
            }
          />
          <MultipleFileUpload
            id={"abdominalUltrasound"}
            files={abdominalUltrasoundFiles}
            removeFile={removeAbdominalUltrasoundFile}
            addFile={addAbdominalUltrasoundFile}
            disabled={!formik.values.abdominalUltrasoundCheckbox}
            maxFileSize={10}
            extensions={["pdf", "jpg", "jpeg", "png", "gif"]}
            fileType={["application/pdf", "image"]}
            fileSource={FileSource.STATE}
            size={"small"}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"ocena włóknienia wątroby"}
            isRequired={true}
          />
          <Paragraph>Jeśli nie było wykonywane wcześniej</Paragraph>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          flexWrap={"nowrap"}
          justifyContent={"space-between"}
        >
          <RadioBox
            isError={
              formik.touched.liverFibrosis &&
              Boolean(formik.errors.liverFibrosis)
            }
            id={"liverFibrosis1"}
            name={"liverFibrosis"}
            isUpperCase={false}
            value={"FIBROSKAN/ELASTIGRAFIA [kPa]"}
            checked={
              formik.values.liverFibrosis === "FIBROSKAN/ELASTIGRAFIA [kPa]"
            }
            onChange={(e) =>
              clearFields<THcvStage02>(
                formik,
                e,
                "FIBROSKAN/ELASTIGRAFIA [kPa]",
                [{name: "apriLiverDesc"}, {name: "fibLiverDesc"}]
              )
            }
          />
          <Input
            width={"84px"}
            name={"fibroSkanDesc"}
            type={"text"}
            isUpperCase={true}
            colorLabel={theme.colors.grey1}
            value={formik.values.fibroSkanDesc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={
              formik.touched.fibroSkanDesc &&
              Boolean(formik.errors.fibroSkanDesc)
            }
          />
          <RadioBox
            isError={
              formik.touched.liverFibrosis &&
              Boolean(formik.errors.liverFibrosis)
            }
            id={"liverFibrosis2"}
            name={"liverFibrosis"}
            value={"APRI"}
            checked={formik.values.liverFibrosis === "APRI"}
            onChange={(e) =>
              clearFields<THcvStage02>(formik, e, "APRI", [
                {name: "fibroSkanDesc"},
                {name: "fibLiverDesc"},
              ])
            }
          />
          <Input
            width={"84px"}
            name={"apriLiverDesc"}
            type={"text"}
            isUpperCase={true}
            colorLabel={theme.colors.grey1}
            value={formik.values.apriLiverDesc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={
              formik.touched.apriLiverDesc &&
              Boolean(formik.errors.apriLiverDesc)
            }
          />
          <RadioBox
            isError={
              formik.touched.liverFibrosis &&
              Boolean(formik.errors.liverFibrosis)
            }
            id={"liverFibrosis3"}
            name={"liverFibrosis"}
            value={"FIB-4"}
            checked={formik.values.liverFibrosis === "FIB-4"}
            onChange={(e) =>
              clearFields<THcvStage02>(formik, e, "FIB-4", [
                {name: "apriLiverDesc"},
                {name: "fibroSkanDesc"},
              ])
            }
          />
          <Input
            width={"84px"}
            name={"fibLiverDesc"}
            type={"text"}
            isUpperCase={true}
            colorLabel={theme.colors.grey1}
            value={formik.values.fibLiverDesc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={
              formik.touched.fibLiverDesc && Boolean(formik.errors.fibLiverDesc)
            }
          />
        </Grid>
        <Grid item xs={12} display={"flex"} gap={"16px"}>
          <Grid item xs={3}>
            <Checkbox
              fontWeight={"700"}
              id={"liverBiopsy"}
              name={"liverBiopsy"}
              fontSize={"12px"}
              textColor={theme.colors.grey1}
              label={"BIOPSJA WĄTROBY"}
              checked={formik.values.liverBiopsy}
              onChange={() =>
                formik.setFieldValue("liverBiopsy", !formik.values.liverBiopsy)
              }
            />
          </Grid>
          <Grid item xs={9}>
            <Input
              disabled={!formik.values.liverBiopsy}
              name={"liverBiopsyDesc"}
              type={"text"}
              isUpperCase={true}
              colorLabel={theme.colors.grey1}
              value={formik.values.liverBiopsyDesc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={
                formik.touched.liverBiopsyDesc &&
                Boolean(formik.errors.liverBiopsyDesc)
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            fontWeight={"700"}
            id={"liverBiopsyCheckbox"}
            name={"liverBiopsyCheckbox"}
            fontSize={"12px"}
            textColor={theme.colors.abbvie}
            label={
              "Oświadczam, że na załączonych zdjęciach nie znajdują sie dane pacjenta*"
            }
            checked={formik.values.liverBiopsyCheckbox}
            onChange={() =>
              formik.setFieldValue(
                "liverBiopsyCheckbox",
                !formik.values.liverBiopsyCheckbox
              )
            }
          />
          <MultipleFileUpload
            id={"liver"}
            files={liverBiopsyFiles}
            removeFile={removeLiverFile}
            disabled={!formik.values.liverBiopsyCheckbox}
            addFile={addLiverFile}
            maxFileSize={10}
            extensions={["pdf", "jpg", "jpeg", "png", "gif"]}
            fileType={["application/pdf", "image"]}
            fileSource={FileSource.STATE}
            size={"small"}
          />
        </Grid>
        <Grid item xs={5} mt={4}>
          <SingleSelect
            defaultValue={"Wybierz"}
            isError={
              formik.touched.hcvGenotype && Boolean(formik.errors.hcvGenotype)
            }
            label={"ocena genotypu hcv"}
            handleChange={(value) => formik.setFieldValue("hcvGenotype", value)}
            selectedValue={formik.values.hcvGenotype}
            elementName={"hcvGenotype"}
            options={genotypeSelect}
          />
        </Grid>
        {formik.values.hcvGenotype === "GT mieszany" && (
          <Grid item xs={5}>
            <Input
              isRequired={true}
              name={"hcvGenotypeMix"}
              type={"text"}
              isUpperCase={true}
              rows={1}
              label={"Miks genotypów"}
              colorLabel={theme.colors.grey1}
              value={formik.values.hcvGenotypeMix}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz..."}
              isError={
                formik.touched.hcvGenotypeMix &&
                Boolean(formik.errors.hcvGenotypeMix)
              }
            />
          </Grid>
        )}
        <Grid item xs={12} mt={4}>
          <Input
            name={"presenceOfComorbidities"}
            type={"textarea"}
            isUpperCase={true}
            rows={1}
            label={"choroby współistniejące"}
            colorLabel={theme.colors.grey1}
            value={formik.values.presenceOfComorbidities}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz..."}
            isError={
              formik.touched.presenceOfComorbidities &&
              Boolean(formik.errors.presenceOfComorbidities)
            }
          />
        </Grid>
        {coInfection === 1 && (
          <Grid item xs={12} mt={2}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Dane na temat koinfekcji Hiv"}
            />
            <Paragraph>
              Dane kliniczne - np. rok rozpoznania zakażenia HIV, historia
              leczenia ARV, wartość limfocytów T CD4, HIV-RNA.
            </Paragraph>
            <Input
              name={"dataHiv"}
              type={"textarea"}
              isUpperCase={true}
              rows={1}
              colorLabel={theme.colors.grey1}
              value={formik.values.dataHiv}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz..."}
              isError={formik.touched.dataHiv && Boolean(formik.errors.dataHiv)}
            />
          </Grid>
        )}
        {coInfection !== null && coInfection >= 3 && (
          <Grid item xs={12} mt={2}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Dane na temat koinfekcji Hbv"}
            />
            <Paragraph>
              Dane kliniczne - np. rok rozpoznania zakażenia HBV, historia
              leczenia przeciwwirusowego, HBeAg, anty-HBe, HBV DNA.
            </Paragraph>
            <Input
              name={"dataHbv "}
              type={"textarea"}
              isUpperCase={true}
              rows={1}
              colorLabel={theme.colors.grey1}
              value={formik.values.dataHbv}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz..."}
              isError={formik.touched.dataHbv && Boolean(formik.errors.dataHbv)}
            />
          </Grid>
        )}
        <Grid item xs={12} mt={2}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            isRequired={true}
            label={"wcześniejsza historia medyczna pacjenta"}
          />
          <Paragraph>
            Proszę podać najważniejsze aspekty medyczne związane z historią
            medyczną pacjenta (np. potencjalnymi okolicznościami zakażenia,
            drogą do diagnozy HCV, manifestacjami pozawątrobwymi, przebiegiem
            wcześniejszych terapii, nasilenia chorób współistniejących itp.)
          </Paragraph>
          <Input
            rows={1}
            name={"previousHistory"}
            type={"textarea"}
            isRequired={true}
            isUpperCase={true}
            colorLabel={theme.colors.grey1}
            value={formik.values.previousHistory}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz historię medyczną pacjenta..."}
            isError={
              formik.touched.previousHistory &&
              Boolean(formik.errors.previousHistory)
            }
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            isRequired={true}
            label={"Czy pacjent był wcześniej leczony przeciwwirusowo?"}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.patientPreviouslyTreatedWithAntivirals &&
                Boolean(formik.errors.patientPreviouslyTreatedWithAntivirals)
              }
              id={"patientPreviouslyTreatedWithAntivirals1"}
              name={"patientPreviouslyTreatedWithAntivirals"}
              value={"nie"}
              checked={
                formik.values.patientPreviouslyTreatedWithAntivirals === "nie"
              }
              onChange={(e) =>
                clearFields<THcvStage02>(formik, e, "nie", [
                  {
                    name: "patientPreviouslyTreatedItems",
                    value: [],
                  },
                  {name: "patientPreviouslyTreatedCount", value: null},
                ])
              }
            />
            <RadioBox
              isError={
                formik.touched.patientPreviouslyTreatedWithAntivirals &&
                Boolean(formik.errors.patientPreviouslyTreatedWithAntivirals)
              }
              id={"patientPreviouslyTreatedWithAntivirals2"}
              name={"patientPreviouslyTreatedWithAntivirals"}
              value={"tak"}
              checked={
                formik.values.patientPreviouslyTreatedWithAntivirals === "tak"
              }
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        {formik.values.patientPreviouslyTreatedWithAntivirals === "tak" && (
          <Grid item xs={12} mt={3}>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                formik.touched.patientPreviouslyTreatedCount &&
                Boolean(formik.errors.patientPreviouslyTreatedCount)
              }
              label={"Ile razy?"}
              handleChange={(value) => {
                formik.setFieldValue("patientPreviouslyTreatedCount", value);
                extraChangeOptionPreviouslyTreatedCount(value as number);
              }}
              selectedValue={formik.values.patientPreviouslyTreatedCount ?? ""}
              elementName={"patientPreviouslyTreatedCount"}
              options={previouslyTreatedCountSelect}
            />
            {formik.values.patientPreviouslyTreatedCount !== null &&
              formik.values.patientPreviouslyTreatedCount >= 1 &&
              [...Array(formik.values.patientPreviouslyTreatedCount)].map(
                (el, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={5} mt={2}>
                      <DateInput
                        openTo={"year"}
                        inputFormat={"yyyy"}
                        views={["year"]}
                        isRequired={true}
                        label={<>Rok leczenia</>}
                        isUpperCase={true}
                        name={`patientPreviouslyTreatedItems[${index}].year`}
                        size={"small"}
                        color={theme.colors.grey1}
                        value={
                          formik.values.patientPreviouslyTreatedItems[index]
                            ?.year
                        }
                        onClose={() => {
                          formik.setFieldTouched(
                            `patientPreviouslyTreatedItems[${index}].year`,
                            true
                          );
                          formik.validateField(
                            `patientPreviouslyTreatedItems[${index}].year`
                          );
                        }}
                        onChange={(e, context) => {
                          formik.setFieldValue(
                            `patientPreviouslyTreatedItems[${index}].year`,
                            e
                          );
                        }}
                        onBlur={() => {
                          formik.setFieldTouched(
                            `patientPreviouslyTreatedItems[${index}].year`,
                            true
                          );
                          setTimeout(() => {
                            formik.validateField(
                              `patientPreviouslyTreatedItems[${index}].year`
                            );
                          }, 0);
                        }}
                        isError={
                          formik.touched.patientPreviouslyTreatedItems &&
                          formik.errors.patientPreviouslyTreatedItems &&
                          typeof formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] === "object" &&
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.year &&
                          Boolean(
                            (
                              formik.errors.patientPreviouslyTreatedItems[
                                index
                                ] as any
                            )?.year
                          )
                        }
                      />
                    </Grid>
                    <Input
                      name={`patientPreviouslyTreatedItems[${index}].description`}
                      type={"textarea"}
                      isRequired={true}
                      isUpperCase={true}
                      label={"opisz schemat"}
                      colorLabel={theme.colors.grey1}
                      value={
                        formik.values.patientPreviouslyTreatedItems[index]
                          ?.description
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz opis schematu..."}
                      isError={
                        formik.touched.patientPreviouslyTreatedItems &&
                        formik.errors.patientPreviouslyTreatedItems &&
                        typeof formik.errors.patientPreviouslyTreatedItems[
                          index
                          ] === "object" &&
                        (
                          formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] as any
                        )?.description &&
                        Boolean(
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.description
                        )
                      }
                    />
                    <Grid item xs={12} mb={1}>
                      <Label
                        size={"small"}
                        isUpperCase={true}
                        color={theme.colors.grey1}
                        label={"odpowiedź na terapię"}
                        isRequired={true}
                      />

                      <RadioBox
                        isError={
                          formik.touched.patientPreviouslyTreatedItems &&
                          formik.errors.patientPreviouslyTreatedItems &&
                          typeof formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] === "object" &&
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.response &&
                          Boolean(
                            (
                              formik.errors.patientPreviouslyTreatedItems[
                                index
                                ] as any
                            )?.response
                          )
                        }
                        id={`formik.values.patientPreviouslyTreatedItems[${index}].response`}
                        name={`formik.values.patientPreviouslyTreatedItems[${index}].response`}
                        value={"nawrót"}
                        checked={
                          formik.values.patientPreviouslyTreatedItems[index]
                            ?.response === "nawrót"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `patientPreviouslyTreatedItems[${index}].response`,
                            e.target.value
                          )
                        }
                      />

                      <RadioBox
                        isError={
                          formik.touched.patientPreviouslyTreatedItems &&
                          formik.errors.patientPreviouslyTreatedItems &&
                          typeof formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] === "object" &&
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.response &&
                          Boolean(
                            (
                              formik.errors.patientPreviouslyTreatedItems[
                                index
                                ] as any
                            )?.response
                          )
                        }
                        id={`formik.values.patientPreviouslyTreatedItems[${index}].response1`}
                        name={`formik.values.patientPreviouslyTreatedItems[${index}].response`}
                        value={"całkowity brak odpowiedzi"}
                        checked={
                          formik.values.patientPreviouslyTreatedItems[index]
                            ?.response === "całkowity brak odpowiedzi"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `patientPreviouslyTreatedItems[${index}].response`,
                            e.target.value
                          )
                        }
                      />
                      <RadioBox
                        isError={
                          formik.touched.patientPreviouslyTreatedItems &&
                          formik.errors.patientPreviouslyTreatedItems &&
                          typeof formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] === "object" &&
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.response &&
                          Boolean(
                            (
                              formik.errors.patientPreviouslyTreatedItems[
                                index
                                ] as any
                            )?.response
                          )
                        }
                        id={`formik.values.patientPreviouslyTreatedItems[${index}].response2`}
                        name={`formik.values.patientPreviouslyTreatedItems[${index}].response`}
                        value={"przełom wirusologiczny"}
                        checked={
                          formik.values.patientPreviouslyTreatedItems[index]
                            ?.response === "przełom wirusologiczny"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `patientPreviouslyTreatedItems[${index}].response`,
                            e.target.value
                          )
                        }
                      />
                      <RadioBox
                        isError={
                          formik.touched.patientPreviouslyTreatedItems &&
                          formik.errors.patientPreviouslyTreatedItems &&
                          typeof formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] === "object" &&
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.response &&
                          Boolean(
                            (
                              formik.errors.patientPreviouslyTreatedItems[
                                index
                                ] as any
                            )?.response
                          )
                        }
                        id={`formik.values.patientPreviouslyTreatedItems[${index}].response3`}
                        name={`formik.values.patientPreviouslyTreatedItems[${index}].response`}
                        value={"osiągnięcie svr a następnie reinfekcja"}
                        checked={
                          formik.values.patientPreviouslyTreatedItems[index]
                            ?.response ===
                          "osiągnięcie svr a następnie reinfekcja"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `patientPreviouslyTreatedItems[${index}].response`,
                            e.target.value
                          )
                        }
                      />
                      <RadioBox
                        isError={
                          formik.touched.patientPreviouslyTreatedItems &&
                          formik.errors.patientPreviouslyTreatedItems &&
                          typeof formik.errors.patientPreviouslyTreatedItems[
                            index
                            ] === "object" &&
                          (
                            formik.errors.patientPreviouslyTreatedItems[
                              index
                              ] as any
                          )?.response &&
                          Boolean(
                            (
                              formik.errors.patientPreviouslyTreatedItems[
                                index
                                ] as any
                            )?.response
                          )
                        }
                        id={`formik.values.patientPreviouslyTreatedItems[${index}].response4`}
                        name={`formik.values.patientPreviouslyTreatedItems[${index}].response`}
                        value={
                          "brak odpowiedzi z powodu przerwania terapii z powodu ae/sae"
                        }
                        checked={
                          formik.values.patientPreviouslyTreatedItems[index]
                            ?.response ===
                          "brak odpowiedzi z powodu przerwania terapii z powodu ae/sae"
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            `patientPreviouslyTreatedItems[${index}].response`,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )
              )}
          </Grid>
        )}
        <Grid item xs={12} mt={4}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"inne elementy ważne z perspektywy pacjenta"}
            isRequired={true}
          />
          <FormikProvider value={formik}>
            <CheckboxGroup>
              <Checkbox
                isError={
                  formik.touched.otherElements &&
                  Boolean(formik.errors.otherElements)
                }
                fontWeight={"700"}
                id={"otherElements1"}
                name={"otherElements"}
                fontSize={"12px"}
                textColor={theme.colors.grey1}
                label={"wyleczenie HCV warunkiem transplantacji nerki"}
                checked={formik.values.otherElements?.includes(
                  "wyleczenie HCV warunkiem transplantacji nerki"
                )}
                onChange={() =>
                  handleCheckbox(
                    "wyleczenie HCV warunkiem transplantacji nerki"
                  )
                }
              />
              <Checkbox
                isError={
                  formik.touched.otherElements &&
                  Boolean(formik.errors.otherElements)
                }
                fontWeight={"700"}
                id={"otherElements2"}
                name={"otherElements"}
                fontSize={"12px"}
                textColor={theme.colors.grey1}
                label={
                  "uwolnienie się od obciążenia psychicznego związanego ze świadomością zakażenia"
                }
                checked={formik.values.otherElements?.includes(
                  "uwolnienie się od obciążenia psychicznego związanego ze świadomością zakażenia"
                )}
                onChange={() =>
                  handleCheckbox(
                    "uwolnienie się od obciążenia psychicznego związanego ze świadomością zakażenia"
                  )
                }
              />
              <Checkbox
                isError={
                  formik.touched.otherElements &&
                  Boolean(formik.errors.otherElements)
                }
                fontWeight={"700"}
                id={"otherElements3"}
                name={"otherElements"}
                fontSize={"12px"}
                textColor={theme.colors.grey1}
                label={
                  "krótki czas stosowania terapii w związku z dużą aktywnością zawodową"
                }
                checked={formik.values.otherElements?.includes(
                  "krótki czas stosowania terapii w związku z dużą aktywnością zawodową"
                )}
                onChange={() =>
                  handleCheckbox(
                    "krótki czas stosowania terapii w związku z dużą aktywnością zawodową"
                  )
                }
              />
              <Checkbox
                isError={
                  formik.touched.otherElements &&
                  Boolean(formik.errors.otherElements)
                }
                fontWeight={"700"}
                id={"otherElements4"}
                name={"otherElements"}
                fontSize={"12px"}
                textColor={theme.colors.grey1}
                label={"chęć zajścia w ciążę"}
                checked={formik.values.otherElements?.includes(
                  "chęć zajścia w ciążę"
                )}
                onChange={() => handleCheckbox("chęć zajścia w ciążę")}
              />
              <Checkbox
                isError={
                  formik.touched.otherElements &&
                  Boolean(formik.errors.otherElements)
                }
                fontWeight={"700"}
                id={"otherElements5"}
                name={"otherElements"}
                fontSize={"12px"}
                textColor={theme.colors.grey1}
                label={"brak"}
                checked={formik.values.otherElements?.includes("brak")}
                onChange={() => handleCheckbox("brak")}
              />
              <Checkbox
                isError={
                  formik.touched.otherElements &&
                  Boolean(formik.errors.otherElements)
                }
                fontWeight={"700"}
                id={"otherElements6"}
                name={"otherElements"}
                fontSize={"12px"}
                textColor={theme.colors.grey1}
                label={"inne"}
                checked={formik.values.otherElements?.includes("inne")}
                onChange={() => handleCheckbox("inne")}
              />
              {formik.values.otherElements?.includes("inne") && (
                <Input
                  name={"otherElementsDesc"}
                  type={"text"}
                  isRequired={true}
                  isUpperCase={true}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.otherElementsDesc}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz..."}
                  isError={
                    formik.touched.otherElementsDesc &&
                    Boolean(formik.errors.otherElementsDesc)
                  }
                />
              )}
            </CheckboxGroup>
          </FormikProvider>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label
            textAlign={"center"}
            size={"medium"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"aktualnie przyjmowane leki"}
            isRequired={true}
            style={{marginBottom: 16}}
          />
          {formik.values.drugs?.map((el: IDrug, index: number) => (
            <DrugElement
              formik={formik}
              deleteDrug={formik.values.drugs.length > 1 ? deleteDrug : null}
              index={index}
              key={index}
            />
          ))}
          <ButtonContainer>
            <ButtonTransparent type="button" onClick={addDrug}>
              dodaj lek
              <Plus/>
            </ButtonTransparent>
          </ButtonContainer>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Input
            name={"potentialDrugInteractions"}
            type={"text"}
            isRequired={true}
            isUpperCase={true}
            label={
              "informacja o potencjalnych interakcjach lekowych lub ich braku"
            }
            colorLabel={theme.colors.grey1}
            value={formik.values.potentialDrugInteractions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz informacje o DDIS..."}
            isError={
              formik.touched.potentialDrugInteractions &&
              Boolean(formik.errors.potentialDrugInteractions)
            }
          />
        </Grid>
        <MultipleFileUpload
          id={"potentialDrugInteractionFile"}
          files={potentialDrugInteractionFiles}
          removeFile={removeDrugInteractionFile}
          addFile={addDrugInteractionFile}
          maxFileSize={10}
          extensions={["pdf", "jpg", "jpeg", "png", "gif"]}
          fileType={["application/pdf", "image"]}
          fileSource={FileSource.STATE}
          size={"small"}
        />
      </Grid>
    </Grid>
  );
});
