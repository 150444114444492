import {observer} from 'mobx-react';
import {useParams} from 'react-router';

// types 
import {EnFormNames} from '../../../../../store/caseSettingsStore';

// components
import {HcvViewCase} from '../forms/virusology/hcv/HcvViewCase';
import {RzsViewCase} from '../forms/rheumatology/rzs/RzsViewCase';
import {LzssViewCase} from "../forms/rheumatology/lzss/LzssViewCase";
import {LzsrViewCase} from "../forms/rheumatology/lzsr/LzsrViewCase";
import {ZzskViewCase} from "../forms/rheumatology/zzsk/ZzskViewCase";

export const ViewCaseCreator = observer(() => {
  const {form} = useParams();
  const formConvert = form as EnFormNames

  switch (formConvert) {
    case EnFormNames.hcv:
      return <HcvViewCase/>
    case EnFormNames.rzs:
      return <RzsViewCase/>
    case EnFormNames.lzss:
      return <LzssViewCase/>
    case EnFormNames.lzsr:
      return <LzsrViewCase/>
    case EnFormNames.zzsk:
      return <ZzskViewCase/>
  }

  return null;
});
  