import {observer} from "mobx-react";

// hooks
import {useRzsStore} from "../../../../../../../../store/hooks";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {GroupForm, Label, NormalText} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";
import theme from "../../../../../../../../theme/theme";

export const TreatmentProgramSection = observer(() => {
  const caseStore = useRzsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={8}>
      <Grid item xs={12} mb={4}>
        <GroupForm>Program lekowy:</GroupForm>
      </Grid>
      <Grid container bgcolor={"white"} p={2}>
        <Label>Moment Włączenia do programu lekowego</Label>
        <Grid item xs={12} mb={4} mt={2}>
          <Label>DATA WEJŚCIA DO PROGRAMU LEKOWEGO</Label>
          <NormalText>
            {checkValueIsEmpty(dateStringify(caseElement.programEntryDate))}
          </NormalText>
        </Grid>
        <Grid container gap={'20px'} mb={4}>
          <Grid item xs={2}>
            <Label>WYNIK DAS 28</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.das28ResultMoi?.toUpperCase())}
            </NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>WYNIK</Label>
            <NormalText>{checkValueIsEmpty(caseElement.resultMoi)}</NormalText>
          </Grid>
          <Grid item>
            <Label>WYNIK skali</Label>
            <NormalText> &lt;2,6 - remisja <br/>
              &le;3,2 – aktywność mała <br/>
              &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
              &gt;5,1 – aktywność duża</NormalText>
          </Grid>
        </Grid>
        <Grid container gap={'20px'} mb={4}>
          <Grid item xs={2}>
            <Label>WYNIK VAS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.vasResultMoi)}</NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>
              WYNIK {caseElement.das28ResultMoi === "crp" ? "OB" : "CRP"}
            </Label>
            <NormalText>
              {checkValueIsEmpty(
                caseElement.das28ResultMoi === "crp"
                  ? caseElement.obResultMoi
                  : caseElement.crpResultMoi
              )}
            </NormalText>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Label>LICZBA BOLESNYCH STAWÓW</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.tenderJointsCountMoi)}
          </NormalText>
        </Grid>
        <Grid item xs={4}>
          <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.swollenJointsCountMoi)}
          </NormalText>
        </Grid>
      </Grid>

      {caseElement.treatmentLines.map((drug, index) => (
        <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
          <Grid item xs={12} mb={2}>
            <Label $size={"large"} $mb={0}>
              {`Linia leczenia ${index + 1}`}
            </Label>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Label>WARIANT</Label>
              <NormalText>{checkValueIsEmpty(drug.variants)}</NormalText>
            </Grid>
            {drug.otherVariantType && <Grid item xs={3}>
              <Label>Inne Rodzaj</Label>
              <NormalText>
                {checkValueIsEmpty(drug.otherVariantType)}
              </NormalText>
            </Grid>}
            {drug.dose && <Grid item xs={4}>
              <Label>Inne dawka</Label>
              <NormalText>
                {checkValueIsEmpty(drug.dose)}
              </NormalText>
            </Grid>}
            {drug.tnfInhibitorType && <Grid item xs={12} mt={2}>
              <Label>Rodzaj inhibitora TNF</Label>
              <NormalText>
                {checkValueIsEmpty(drug.tnfInhibitorType)}
              </NormalText>
            </Grid>}
            {drug.adalimumabOtherVariant && <Grid item xs={12} mt={2}>
              <Label>adalimumab inne rodzaj</Label>
              <NormalText>
                {checkValueIsEmpty(drug.adalimumabOtherVariant)}
              </NormalText>
            </Grid>}
            {drug.adalimumabOtherDose && <Grid item xs={12} mt={2}>
              <Label $mb={0}>adalimumab inne dawka</Label>
              <Typography variant={'caption'} color={theme.colors.orange}>mg/tydzień</Typography>
              <NormalText>
                {checkValueIsEmpty(drug.adalimumabOtherDose)}
              </NormalText>
            </Grid>}
            {drug.il6Dose && <Grid item xs={12} mt={2}>
              <Label $mb={0}>Rodzaj inhibitora IL-6:</Label>
              <Typography variant={'caption'} color={theme.colors.orange}>Tocilizumab</Typography>
              <Label>Dawka</Label>
              <NormalText>
                {checkValueIsEmpty(drug.il6Dose)}
              </NormalText>
            </Grid>}

            {drug.jakInhibitorType && <Grid item xs={12} mt={2}>
              <Label>Rodzaj inhibitora JAK</Label>
              <NormalText>
                {checkValueIsEmpty(drug.jakInhibitorType)}
              </NormalText>
            </Grid>}
            <Grid item xs={12} mt={4} mb={4}>
              <Label>Monoterapia czy kombinacja z innym lekiem?</Label>
              <NormalText>{checkValueIsEmpty(drug.monotherapy)}</NormalText>
            </Grid>
            {drug.combinationWithMedicine.length > 0 && <Divider
              style={{width: "100%"}}
              variant={"fullWidth"}
              sx={{height: "2px", margin: '0 0 32px 0'}}
            />}
            {
              drug.combinationWithMedicine.map((el, idx) => (
                <Grid container key={idx}>
                  <Grid item xs={12} mb={2}>
                    <Label $size={"large"} $mb={0}>
                      {`Kombinacja ${idx + 1}`}
                    </Label>
                  </Grid>
                  <Grid container gap={2}>
                    <Grid item xs={12}>
                      <Label>Kombinacja Z jakim lekiem?</Label>
                      <NormalText>{checkValueIsEmpty(el.combinationDrugName)}</NormalText>
                    </Grid>
                    {el.otherCombinationDrug && el.combinationDrugName === 'Inne' && <Grid item xs={12}>
                      <Label>nazwa innego leku</Label>
                      <NormalText>{checkValueIsEmpty(el.otherCombinationDrug)}</NormalText>
                    </Grid>}
                    <Grid item xs={12}>
                      <Label>Dawka</Label>
                      <NormalText>{checkValueIsEmpty(el.combinationDose)}
                        {checkValueIsEmpty(el.combinationUnit === 'Inna jednostka' ? el.otherCombinationUnit : el.combinationUnit)}
                      </NormalText>
                    </Grid>
                    <Grid item xs={12}>
                      <Label>Zakres czasu przyjmowania</Label>
                      <Typography variant={'caption'} color={theme.colors.orange}>liczba
                        tygodni</Typography>
                      <NormalText>{checkValueIsEmpty(el.combinationDuration)}</NormalText>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{width: "100%"}}
                    variant={"fullWidth"}
                    sx={{height: "2px", margin: "32px 0"}}
                  />
                </Grid>
              ))
            }
            <Grid container gap={'20px'} mb={4}>
              <Grid item xs={2}>
                <Label>WYNIK DAS 28</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.das28FollowupResult)}
                </NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(drug.followupResult)}</NormalText>
              </Grid>
              <Grid item>
                <Label>WYNIK skali</Label>
                <NormalText> &lt;2,6 - remisja <br/>
                  &le;3,2 – aktywność mała <br/>
                  &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                  &gt;5,1 – aktywność duża</NormalText>
              </Grid>
            </Grid>
            <Grid container gap={'20px'} mb={4}>
              <Grid item xs={2}>
                <Label>WYNIK VAS</Label>
                <NormalText>{checkValueIsEmpty(drug.followupVasResult)}</NormalText>
              </Grid>

              <Grid item xs={2}>
                <Label>
                  WYNIK {drug.das28FollowupResult === "crp" ? "OB" : "CRP"}
                </Label>
                <NormalText>
                  {checkValueIsEmpty(
                    drug.das28FollowupResult === "crp"
                      ? drug.followupObResult
                      : drug.followupCrpResult
                  )}
                </NormalText>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Label>LICZBA BOLESNYCH STAWÓW</Label>
              <NormalText>
                {checkValueIsEmpty(drug.followupTenderJointsCount)}
              </NormalText>
            </Grid>
            <Grid item xs={4}>
              <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
              <NormalText>
                {checkValueIsEmpty(drug.followupSwollenJointsCount)}
              </NormalText>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Label>Czy były objawy nietolerancji wybranego leku?</Label>
              <NormalText>{checkValueIsEmpty(drug.symptomsOfIntolerance)}</NormalText>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
});
