import * as yup from "yup";
// hooks
import {useFormik} from "formik";
import {useCaseSettingsStore, useRzsStore,} from "../../../../../../../../../store/hooks";
// types
import {TRzsStage04} from "./type";
import {EnAreaNames, EnFormNames,} from "../../../../../../../../../store/caseSettingsStore";
import {useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

const validationSchema = yup.object().shape({
  drugInitiationDate: yup.string().required(),
  das28Result3: yup.string().required(),
  result3: yup.number().required(),
  vasResult3: yup.number().required(),
  obResult3: yup
    .number()
    .nullable()
    .integer()
    .when("das28Result3", {
      is: (value: string) => value === "crp",
      then: (schema) => schema.required(),
    }),
  crpResult3: yup
    .number()
    .integer()
    .nullable()
    .when("das28Result3", {
      is: (value: string) => value === "ob",
      then: (schema) => schema.required(),
    }),
  tenderJointsCount3: yup.number().min(0).max(10).integer().required(),
  swollenJointsCount3: yup.number().min(0).max(10).integer().required(),
  observationIntervals: yup.string().required(),
  observations: yup
    .array()
    .of(
      yup.object().shape({
        monotherapy: yup.string().required(),
        combinationWithMedicine: yup
          .array()
          .of(
            yup.object().shape({
              combinationDrugName: yup.string().required(),
              otherCombinationDrug: yup.string().when("combinationDrugName", {
                is: (value: string) => value === "Inne",
                then: (schema) => schema.required(),
              }),
              combinationDose: yup.number().required(),
              combinationUnit: yup.string().required(),
              otherCombinationUnit: yup.string().when("combinationUnit", {
                is: (value: string) => value === "Inna jednostka",
                then: (schema) => schema.required(),
              }),
              combinationDuration: yup.number().integer().required(),
            })
          )
          .when("monotherapy", {
            is: (value: string) => value === "kombinacja",
            then: (schema) => schema.min(1).required(),
          }),
        das28Result: yup.string().required(),
        result: yup.number().required(),
        vasResult: yup.number().required(),
        obResult: yup
          .number()
          .nullable()
          .when("das28Result", {
            is: (value: string) => value === "crp",
            then: (schema) => schema.required(),
          }),
        crpResult: yup
          .number()
          .nullable()
          .when("das28Result", {
            is: (value: string) => value === "ob",
            then: (schema) => schema.required(),
          }),
        tenderJointsCount: yup.number().min(0).max(10).required(),
        swollenJointsCount: yup.number().min(0).max(10).required(),
        gks: yup.string().required(),
      })
    )
    .when("observationIntervals", {
      is: (value: string) => value === "co 1 msc.",
      then: (schema) => schema.length(6).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 3 msc.",
      then: (schema) => schema.length(2).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 6 msc.",
      then: (schema) => schema.length(1).required(),
    }),
});

const initialValues: TRzsStage04 = {
  drugInitiationDate: null,
  das28Result3: "",
  result3: null,
  vasResult3: null,
  obResult3: null,
  crpResult3: null,
  tenderJointsCount3: null,
  swollenJointsCount3: null,
  observationIntervals: "",
  observations: [],
};

export const useStage04ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const {fourthStage, caseStage} = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage04>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`observations[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `observations[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const observationObject = {
    monotherapy: "",
    combinationWithMedicine: [],
    das28Result: "",
    result: null,
    vasResult: null,
    obResult: null,
    crpResult: null,
    tenderJointsCount: null,
    swollenJointsCount: null,
    gks: "",
  }

  const modifyObservations = (value: string) => {
    switch (value) {
      case "co 1 msc.":
        const additionalObservations = 6 - formik.values.observations.length;

        const additionalObjects = Array.from(
          {length: additionalObservations},
          () => JSON.parse(JSON.stringify(observationObject))
        );
        formik.setFieldValue("observations", [
          ...formik.values.observations,
          ...additionalObjects,
        ]);
        return;
      case "co 3 msc.":
        const additionalObservations3 = 2 - formik.values.observations.length;

        if (formik.values.observations.length >= 2) {
          const newObservations = [...formik.values.observations.slice(0, 2)];
          formik.setFieldValue("observations", [...newObservations]);
        } else {
          const additionalObjects3 = Array.from(
            {length: additionalObservations3},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          formik.setFieldValue("observations", [
            ...formik.values.observations,
            ...additionalObjects3,
          ]);
        }
        return;
      case "co 6 msc.":
        const additionalObservations6 = 1 - formik.values.observations.length;
        if (formik.values.observations.length >= 1) {
          const newObservations = [...formik.values.observations.slice(0, 1)];
          formik.setFieldValue("observations", [...newObservations]);
        } else {
          const additionalObjects6 = Array.from(
            {length: additionalObservations6},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          formik.setFieldValue("observations", [
            ...formik.values.observations,
            ...additionalObjects6,
          ]);
        }
        //formik.validateForm().then();
        return;
    }
  };

  const addCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.observations[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `observations[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      ...formik.values.observations[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  return {
    formik,
    setOption,
    modifyObservations,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
  };
};
