import { useEffect } from "react";
import { useCaseSettingsStore, useCasesStore } from "../../../../store/hooks";
import { useCasesApi } from "../../../../apiHooks";
import { generateLink, UserLink } from "../../../../utils";
import { useNavigate } from "react-router-dom";

export const useMyCases = () => {
  const casesStore = useCasesStore();
  const navigate = useNavigate();
  const caseSettingsStore = useCaseSettingsStore();
  const {userPrescribedAreas} = caseSettingsStore;

  const {
    cases,
    isListOfMyCasesLoaded,
    switchFilters,
    switchCoinfectionFilters,
    filters,
    filteredListOfCasesByCurrentPage,
    filterForms,
    switchFormFilters,
    coinfections,
    searchValue,
    pageCount,
    currentPage,
    setCurrentPage,
    forms,
  } = casesStore;

  const {getListOfMyCases} = useCasesApi();

  useEffect(() => {
    if (!isListOfMyCasesLoaded) {
      if (cases === null) {
        void getListOfMyCases();
      } else {
        void getListOfMyCases(false);
      }
    }
  }, [getListOfMyCases, cases, isListOfMyCasesLoaded]);

  useEffect(() => {
    return () => {
      casesStore.resetListOfMyCases();
    };
  }, [casesStore]);

  const addCase = () => {
    const isOneAreaAndOneForm =
      userPrescribedAreas?.length === 1 &&
      userPrescribedAreas[0].forms.length === 1;

    navigate(
      isOneAreaAndOneForm
        ? generateLink(UserLink.ADD_CASE, [
          [":area", userPrescribedAreas[0].name],
          [":form", userPrescribedAreas[0].forms[0].name],
        ])
        : UserLink.SET_AREA_AND_FORM
    );
  };

  const onChangeSearchHandler = (value: string) => {
    casesStore.setSearchValue(value);
  }

  return {
    filteredListOfCasesByCurrentPage,
    addCase,
    searchValue,
    onChangeSearchHandler,
    pageCount,
    currentPage,
    setCurrentPage,
    switchFilters,
    filterStatus: filters,
    switchCoinfectionFilters,
    filterCoinfections: coinfections,
    filterForms,
    switchFormFilters,
    forms,
  };
};
