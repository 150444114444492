// TODO: remove this file
import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const RadioForm = styled.div<{ $flexDirection?: string }>`
  display: flex;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : "row"};
  gap: 16px;
  height: 32px;
  align-items: center;
`;

export const SelectContainer = styled.div<{
  $isError?: boolean;
  $isSelected: boolean;
}>`
  select {
    height: 36px;
    border: 2px solid
      ${(props) => (props.$isError ? theme.colors.red : theme.colors.grey4)};
    border-radius: 10px;
    font-size: 16px;
    line-height: 22px;
    padding: 0 10px;
    margin: 0 0 15px 0;
    background: ${theme.colors.white};

    option {
      color: ${theme.colors.black};
    }
  }
`;
