import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Grid } from "@mui/material";

import { CellTime } from '../../../../shared/customTables/cells/cellTime/CellTime';
import { createTableRows } from '../../../../shared/customTables/utils/createTableRows';
import { useHistoryStore } from '../../../../../store/hooks';
import { IHeadCell } from '../../../../../interfaces/common/table';
import { AdminLink, generateLink, splitData, SplitData } from '../../../../../utils';
import { ReactComponent as HistoryListIcon } from "../../../../../assets/icon/historyList.svg"
import { ReactComponent as SearchIcon } from "../../../../../assets/icon/search.svg"
import {
  CaseTitle,
  Header,
  HeaderContainer,
  HistoryHeader,
  InfoContainer,
  SearchIconContainer
} from './HistoryItems.styled';
import { TableCellData } from '../../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled';
import { useCaseApi } from '../../../../../apiHooks';
import { EnAreaNames, EnFormNames } from "../../../../../store/caseSettingsStore";

export const useHistoryItems = () => {
  const {caseId, area, form} = useParams();
  const navigate = useNavigate();
  const {getCaseComments} = useCaseApi();
  const historyStore = useHistoryStore();
  const {history, isHistoryLoaded} = historyStore;
  const formConvert = form as EnFormNames
  const areaConvert = area as EnAreaNames
  useEffect(() => {
    if (!isHistoryLoaded && caseId) {
      if (history === null) {
        void getCaseComments(+caseId, areaConvert, formConvert);
      } else {
        void getCaseComments(+caseId, areaConvert, formConvert, false);
      }
    }
  }, [isHistoryLoaded, getCaseComments, history, caseId, areaConvert, formConvert])

  useEffect(() => {
    return () => {
      historyStore.resetHistory();
    }
  }, [historyStore])

  const cellsSwitcher = (keyName: string, value: string, onClick?: () => void) => {
    const specialValue: SplitData = keyName === 'createdAt' ? splitData(value.toString()) : ['-', ''];

    switch (keyName) {
      case 'id':
        return null;
      case 'createdAt':
        return <TableCellData><CellTime specialValue={specialValue}/></TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const headCells: IHeadCell[] = [
    {id: 'createdAt', numeric: false, label: 'data utworzenia'},
    {id: 'comment', numeric: false, label: 'powód zwrócenia do korekty'}
  ];

  const tableDataRows = createTableRows(history?.items ?? [], headCells, false);

  const onClickSearchHandler = () => {
    navigate(generateLink(AdminLink.VIEW_CASE, [
      [":caseId", String(caseId)],
      [":area", String(areaConvert)],
      [":form", String(formConvert)],
    ]))
  }

  const addedContent = history ?
    <HistoryHeader container>
      <Grid item xs={12}>
        <InfoContainer>
          <p>
            <b>Id opisu przypadku: </b>{history.customId}
          </p>
          <p>
            <b>Lekarz: </b>{history.doctor}
          </p>
        </InfoContainer>
      </Grid>
      <Grid item xs={12} mt={1}>
        <HeaderContainer><HistoryListIcon/><Header>Historia edycji opisu przypadku:</Header></HeaderContainer>
      </Grid>
      <Grid item xs={12} display={'flex'} gap={1} mt={1} alignItems={'center'}>
        <SearchIconContainer onClick={onClickSearchHandler}>
          <SearchIcon/>
        </SearchIconContainer>
        <CaseTitle>{history.title}</CaseTitle>
      </Grid>
    </HistoryHeader> : <></>;

  return {
    historyIsNotNull: history !== null,
    cellsSwitcher,
    tableDataRows,
    headCells,
    addedContent
  };
};