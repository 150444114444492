import { IThirdStage } from "../../../../../../../../../interfaces/user/draftCase/thirdStage";
import { FieldToClear } from "../../../../../../../../../utils/clearFields";

export const therapyArray = [
  {
    id: 1,
    name: "therapy",
    value: "nawrót",
  },
  {
    id: 2,
    name: "therapy",
    value: "całkowity brak odpowiedzi",
  },
  {
    id: 3,
    name: "therapy",
    value: "przełom wirusologiczny",
  },
  {
    id: 4,
    name: "therapy",
    value: "osiągnięcie SVR a następnie reinfekcja",
  },
  {
    id: 5,
    name: "therapy",
    value: "brak odpowiedzi z powodu przerwania terapii z powodu AEs",
  },
];

export const durationOfTherapy4Week: FieldToClear<IThirdStage>[] = [
  { name: "rbc4", value: "" },
  { name: "hgb4", value: "" },
  { name: "htc4", value: "" },
  { name: "plt4", value: "" },
  { name: "wbc4", value: "" },
  { name: "mcv4", value: "" },
  { name: "mch4", value: "" },
  { name: "mchc4", value: "" },
  { name: "aiat4", value: "" },
  { name: "bilirubin4", value: "" },
  { name: "bilirubinMonitoring4", value: "" },
];

export const durationOfTherapy8Week: FieldToClear<IThirdStage>[] = [
  { name: "rbc8", value: "" },
  { name: "hgb8", value: "" },
  { name: "htc8", value: "" },
  { name: "plt8", value: "" },
  { name: "wbc8", value: "" },
  { name: "mcv8", value: "" },
  { name: "mch8", value: "" },
  { name: "mchc8", value: "" },
  { name: "aiat8", value: "" },
  { name: "bilirubin8", value: "" },
  { name: "bilirubinMonitoring8", value: "" },
];

export const durationOfTherapy12Week: FieldToClear<IThirdStage>[] = [
  { name: "rbc12", value: "" },
  { name: "hgb12", value: "" },
  { name: "htc12", value: "" },
  { name: "plt12", value: "" },
  { name: "wbc12", value: "" },
  { name: "mcv12", value: "" },
  { name: "mch12", value: "" },
  { name: "mchc12", value: "" },
  { name: "aiat12", value: "" },
  { name: "bilirubin12", value: "" },
  { name: "bilirubinMonitoring12", value: "" },
];
