import React from "react";
import { observer } from "mobx-react";

// hooks
import { useStage02ViewModel } from "./Stage02.vm";

// components
import { Grid } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";

// helpers, types, styles
import { vasOptions } from "./Stage02.helper";
import theme from "../../../../../../../../../theme/theme";
import { RadioForm } from "../../../../../stages/firstStage/FirstStage.styled";

export const Stage02 = observer(() => {
  const {formik, setOption} = useStage02ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item pb={2} display={"flex"} flexDirection={"column"}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Rozpoznanie ZZSK na podstawie zmodyfikowanych kryteriów nowojorskich z uwzględnieniem sacroiliitis w badaniu RTG"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={
              formik.touched.zzskDiagnosis && Boolean(formik.errors.zzskDiagnosis)
            }
            id={"zzskDiagnosis"}
            name={"zzskDiagnosis"}
            value={"tak"}
            checked={formik.values.zzskDiagnosis === "tak"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={
              formik.touched.zzskDiagnosis && Boolean(formik.errors.zzskDiagnosis)
            }
            id={"zzskDiagnosis2"}
            name={"zzskDiagnosis"}
            value={"nie"}
            checked={formik.values.zzskDiagnosis === "nie"}
            onChange={formik.handleChange}
          />
        </RadioForm>
      </Grid>
      <Label
        size={"superSmall"}
        isUpperCase={false}
        color={theme.colors.orange}
        label={"Niezadowalająca odpowiedz na co najmniej dwa niesteroidowe leki przeciwzapalne, z których każdy zastosowany był w maksymalnej rekomendowanej lub tolerowanej przez chorego dawce przez co najmniej 4 tygodnie w monoterapii. Niezadowalająca odpowiedź na leczenie oznacza utrzymywanie się aktywnej postaci choroby pomimo zastosowanego leczenia."}
      />
      {formik.values.zzskDiagnosis === "tak" && <>
        <Grid item xs={12} mt={2}>
          <DateInput
            disableFuture={true}
            openTo={"year"}
            inputFormat={"yyyy/MM/dd"}
            views={["year", "month", "day"]}
            isRequired={true}
            maxWidth={"254px"}
            label={<>Data rozpoznania</>}
            isUpperCase={true}
            name={"diagnosisDate"}
            size={"small"}
            color={theme.colors.grey1}
            value={formik.values.diagnosisDate}
            onClose={() => {
              formik.setFieldTouched("diagnosisDate", true);
              formik.validateField("diagnosisDate");
            }}
            onChange={(e, context) => {
              formik.setFieldValue("diagnosisDate", e);
            }}
            onBlur={() => {
              formik.setFieldTouched("diagnosisDate", true);
              setTimeout(() => {
                formik.validateField("diagnosisDate");
              }, 0);
            }}
            isError={
              formik.touched.diagnosisDate && Boolean(formik.errors.diagnosisDate)
            }
          />
        </Grid>
        <Grid container display={"flex"} gap={"20px"} mt={2}>
          <Grid item pb={2} display={"flex"} flexDirection={"column"}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"podaj wynik w skali"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.resultScaleOption && Boolean(formik.errors.resultScaleOption)
                }
                id={"resultScaleOption"}
                name={"resultScaleOption"}
                value={"basdai"}
                checked={formik.values.resultScaleOption === "basdai"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={
                  formik.touched.resultScaleOption && Boolean(formik.errors.resultScaleOption)
                }
                id={"resultScaleOption2"}
                name={"resultScaleOption"}
                value={"asdas"}
                checked={formik.values.resultScaleOption === "asdas"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
          <Grid item xs={2}>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                formik.touched.result && Boolean(formik.errors.result)
              }
              label={"Wynik"}
              handleChange={(value) => setOption(value, "result")}
              selectedValue={formik.values.result ?? ""}
              elementName={"result"}
              options={vasOptions}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.vasResult && Boolean(formik.errors.vasResult)
            }
            label={"Wynik Vas"}
            handleChange={(value) => setOption(value, "vasResult")}
            selectedValue={formik.values.vasResult ?? ""}
            elementName={"vasResult"}
            options={vasOptions}
          />
        </Grid>
        <Grid item xs={12} mt={2} mb={2}>
          <Input
            label={"Komentarz istotny klinicznie"}
            isUpperCase={true}
            name={"relevantComment"}
            type={"textarea"}
            colorLabel={theme.colors.grey1}
            value={formik.values.relevantComment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.relevantComment && Boolean(formik.errors.relevantComment)}
          />
        </Grid>
        <Grid item xs={12} pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Glikokortykosteroidy (GKS)"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.gks && Boolean(formik.errors.gks)
              }
              id={"gks"}
              name={"gks"}
              value={"tak"}
              checked={formik.values.gks === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.gks && Boolean(formik.errors.gks)
              }
              id={"gks2"}
              name={"gks"}
              value={"nie"}
              checked={formik.values.gks === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid xs={12} item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"jednokrotne miejscowe podania GKS"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.singleGks && Boolean(formik.errors.singleGks)
              }
              id={"singleGks"}
              name={"singleGks"}
              value={"tak"}
              checked={formik.values.singleGks === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.singleGks && Boolean(formik.errors.singleGks)
              }
              id={"singleGks2"}
              name={"singleGks"}
              value={"nie"}
              checked={formik.values.singleGks === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
      </>}
    </Grid>
  );
});
