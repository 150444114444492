import { observer } from "mobx-react";
import { FormikErrors } from "formik";

// hooks
import { useStage04ViewModel } from "./Stage04.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";

// lib
import theme from "../../../../../../../../../theme/theme";

//icons
//types, helpers, styles
import { IObservations } from "./type";
import { vasOptions } from "./Stage04.helper";
import { RadioForm } from "../../../../../stages/firstStage/FirstStage.styled";
import React from "react";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";

export const Stage04 = observer(() => {
  const {
    formik,
    setOption,
    modifyObservations,
  } = useStage04ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Wyniki przed włączeniem leku Rinvoq
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data włączenia leku Rinvoq</>}
          isUpperCase={true}
          name={"drugInitiationDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.drugInitiationDate}
          onClose={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            formik.validateField("drugInitiationDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("drugInitiationDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            setTimeout(() => {
              formik.validateField("drugInitiationDate");
            }, 0);
          }}
          isError={
            formik.touched.drugInitiationDate &&
            Boolean(formik.errors.drugInitiationDate)
          }
        />
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mt={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"podaj wynik w skali"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.resultScaleOption4 && Boolean(formik.errors.resultScaleOption4)
              }
              id={"resultScaleOption4"}
              name={"resultScaleOption4"}
              value={"basdai"}
              checked={formik.values.resultScaleOption4 === "basdai"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.resultScaleOption4 && Boolean(formik.errors.resultScaleOption4)
              }
              id={"resultScaleOption42"}
              name={"resultScaleOption4"}
              value={"asdas"}
              checked={formik.values.resultScaleOption4 === "asdas"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid item xs={2}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.result4 && Boolean(formik.errors.result4)
            }
            label={"Wynik"}
            handleChange={(value) => setOption(value, "result4")}
            selectedValue={formik.values.result4 ?? ""}
            elementName={"result43"}
            options={vasOptions}
          />
        </Grid>
      </Grid>

      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item xs={3}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.vasResult4 && Boolean(formik.errors.vasResult4)
            }
            label={"Wynik Vas"}
            handleChange={(value) => setOption(value, "vasResult4")}
            selectedValue={formik.values.vasResult4 ?? ""}
            elementName={"vasResult4"}
            options={vasOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            isRequired={true}
            label={"Wynik CRP"}
            isUpperCase={true}
            name={"crpResult4"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue('crpResult4', null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.crpResult4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.crpResult4 &&
              Boolean(formik.errors.crpResult4)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            onChangeCustom={() => formik.setFieldValue('obResult4', null)}
            label={"Wynik OB"}
            isUpperCase={true}
            name={"obResult4"}
            type={"number"}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.obResult4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.obResult4 && Boolean(formik.errors.obResult4)
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={2} mt={3}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Obserwacja na leku Rinvoq
        </Typography>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Interwały obserwacji"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals"}
              name={"observationIntervals"}
              value={"co 1 msc."}
              checked={formik.values.observationIntervals === "co 1 msc."}
              onChange={(e) => {
                formik.handleChange(e)
                modifyObservations(e.target.value)
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals2"}
              name={"observationIntervals"}
              value={"co 3 msc."}
              checked={formik.values.observationIntervals === "co 3 msc."}
              onChange={(e) => {
                formik.handleChange(e)
                modifyObservations(e.target.value)
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals3"}
              name={"observationIntervals"}
              value={"co 6 msc."}
              checked={formik.values.observationIntervals === "co 6 msc."}
              onChange={(e) => {
                formik.handleChange(e)
                modifyObservations(e.target.value)
              }}
            />
          </RadioForm>
        </Grid>
      </Grid>
      {formik.values.observations.length > 0 && <><Divider
        style={{width: "100%"}}
        variant={"fullWidth"}
        sx={{height: "2px", margin: "0 0 24px 0"}}
      />
        <Grid
          item
          xs={12}
          mb={2}
          mt={2}
          display={"flex"}
          justifyContent={"center"}
        >
          <Typography
            variant={"h6"}
            color={theme.colors.grey1}
            textTransform={"uppercase"}
            fontWeight={700}
          >
            Obserwacje
          </Typography>
        </Grid>
        <Divider
          style={{width: "100%"}}
          variant={"fullWidth"}
          sx={{height: "2px", margin: "16px 0 16px 0"}}
        /></>}
      <Grid container>
        {formik.values.observations?.map((line, index) => (
          <Grid
            key={index}
            item
            xs={12}
            mb={4}
          >
            <Grid
              item
              xs={12}
              mt={2}
              mb={4}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Obserwacja {index + 1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Grid container display={"flex"} gap={"20px"}>
                <Grid item xs={12} pb={2} display={"flex"} flexDirection={"column"}>
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Glikokortykosteroidy (GKS)"}
                    isRequired={true}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.gks &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                              ] as FormikErrors<IObservations>
                          )?.gks
                        )
                      }
                      id={`observations1-${index}-gks`}
                      name={`observations[${index}].symptomsOfIntolerance`}
                      value={"tak"}
                      checked={formik.values.observations[index].gks === "tak"}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].gks`,
                          e.target.value
                        );
                      }}
                    />
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.gks &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                              ] as FormikErrors<IObservations>
                          )?.gks
                        )
                      }
                      id={`observations2-${index}-gks`}
                      name={`observations[${index}].gks`}
                      value={"nie"}
                      checked={formik.values.observations[index].gks === "nie"}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].gks`,
                          e.target.value
                        );
                      }}
                    />
                  </RadioForm>
                </Grid>
                <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Podaj wynik w skali"}
                    isRequired={true}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]
                          ?.resultScaleOption &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                              ] as FormikErrors<IObservations>
                          )?.resultScaleOption
                        )
                      }
                      id={`resultScaleOption-${index}`}
                      name={`observations[${index}].resultScaleOption`}
                      value={"basdai"}
                      checked={
                        formik.values.observations[index]
                          .resultScaleOption === "basdai"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].resultScaleOption`,
                          e.target.value
                        );
                      }}
                    />
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]
                          ?.resultScaleOption &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                              ] as FormikErrors<IObservations>
                          )?.resultScaleOption
                        )
                      }
                      id={`resultScaleOption2-${index}`}
                      name={`observations[${index}].resultScaleOption`}
                      value={"asdas"}
                      checked={
                        formik.values.observations[index]
                          .resultScaleOption === "asdas"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].resultScaleOption`,
                          e.target.value
                        );
                      }}
                    />
                  </RadioForm>
                </Grid>
                <Grid item xs={2}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]?.result &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.result
                      )
                    }
                    label={"Wynik"}
                    handleChange={(value) =>
                      setOption(value, "result", index)
                    }
                    selectedValue={
                      formik.values.observations[index]?.result ?? ""
                    }
                    elementName={`observations[${index}].result`}
                    options={vasOptions}
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                <Grid item xs={3}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]?.vasResult &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.vasResult
                      )
                    }
                    label={"Wynik Vas"}
                    handleChange={(value) =>
                      setOption(value, "vasResult", index)
                    }
                    selectedValue={
                      formik.values.observations[index]?.vasResult ?? ""
                    }
                    elementName={`observations[${index}].vasResult`}
                    options={vasOptions}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    isRequired={true}
                    label={"Wynik CRP"}
                    isUpperCase={true}
                    name={`observations[${index}].crpResult`}
                    type={"number"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.observations?.[index]
                        ?.crpResult
                    }
                    onChangeCustom={() => formik.setFieldValue(`observations[${index}].crpResult`, null)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]
                        ?.crpResult &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.crpResult
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label={"Wynik OB"}
                    onChangeCustom={() => formik.setFieldValue(`observations[${index}].obResult`, null)}
                    isUpperCase={true}
                    name={`observations[${index}].obResult`}
                    type={"number"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.observations?.[index]
                        ?.obResult
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]
                        ?.obResult &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.obResult
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
});
