export const comorbiditiesOptions = [
  "Gruźlica",
  "Wcześniejsza przebyta choroba onkologiczna",
  "Przewlekły półpasiec",
  "Nadciśnienie tętnicze",
  "Łuszczyca",
  "Choroby zapalne jelit",
  "Zapalenie błony naczyniowej oka",
  "Atopowe zapalenie skóry (AZS)",
  "Miażdżyca tętnic",
  "Inne",
];
