import { useNavigate, useParams } from "react-router-dom";
import {
  useCaseStore,
  useProgressBarStore,
} from "../../../../../../store/hooks";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { generateLink, UserLink } from "../../../../../../utils";

export const useFifthStage = () => {
  const navigate = useNavigate();
  const { caseId, area, form } = useParams();

  const progressBarStore = useProgressBarStore();
  const draftCaseStore = useCaseStore();

  const clickButtonHandler = async (type: string) => {
    progressBarStore.setTypeOfCase(type);
    if (type === "publish" && draftCaseStore.case) {
    } else if (type === "draft") {
      progressBarStore.setCurrentStage(Stages.SIXTH);
    }
  };

  const navigateToView = () => {
    navigate(
      generateLink(UserLink.VIEW_CASE, [
        [":caseId", String(caseId)],
        [":area", String(area)],
        [":form", String(form)],
      ])
    );
  };

  return { clickButtonHandler, navigateToView };
};
