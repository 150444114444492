// TODO: replace file to form folder
import { IFile } from './file';
import { IDrug,  IPreviouslyTreated } from "./secondStage";

export type Gender = "M" | "W";

export enum CaseStatus {
  DRAFT = "DRAFT",
  SENT = "SENT",
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  WAIT = "WAIT",
  TO_ACCEPT = "TO_ACCEPT",
}

export interface Comment {
  id: number;
  createdAt: string;
  comment: string;
}

export type TCommonCase = {
  id: number;
  comments: Comment[];
  createdBy: string;
  customId: string;
  step: number;
  status: CaseStatus;
  title: string;
  initials: string;
}

export type ICase = TCommonCase & {
  age: string | number | null;
  gender: string | null;
  draftCase: string;
  institutions: string;
  coInfection: number;
  firstTreatment: string | boolean | null;
  dateOfDetection?: string | null;
  placeOfDetection: number;
  routeOfInfection: string;
  routeOfInfectionDesc?: string;
  scaleScore: string;
  metavirDesc?: string;
  apriDesc?: string;
  clinicalSignsOfCirrhosis: string;
  extrahepaticManifestationsOfHCV: string;
  hcvRna: string;
  hcvRnaUnit: string;
  rbc: string;
  hgb: string;
  htc: string;
  plt: string;
  wbc: string;
  mcv: string;
  mch: string;
  mchc: string;
  aiat: string;
  albumin: string;
  bilirubin: string;
  prothrombin: string;
  prothrombinUnit: string;
  creatinine: string;
  egfr: string;
  antiHiv: string;
  hbs: string;
  abdominalUltrasound: string;
  abdominalUltrasoundFiles?: IFile[];
  abdominalUltrasoundCheckbox: boolean;
  liverFibrosis: string;
  fibroSkanDesc?: string;
  apriLiverDesc?: string;
  fibLiverDesc?: string;
  liverBiopsy: boolean;
  liverBiopsyDesc?: string;
  liverBiopsyCheckbox: boolean;
  liverBiopsyFiles?: IFile[];
  hcvGenotype?: string;
  hcvGenotypeMix?: string;
  presenceOfComorbidities: string;
  dataHiv: string;
  dataHbv: string;
  previousHistory: string;
  otherElements: string[];
  otherElementsDesc: string;
  patientPreviouslyTreatedWithAntivirals: string;
  patientPreviouslyTreatedCount: number;
  patientPreviouslyTreatedItems: IPreviouslyTreated[];
  drugs: IDrug[];
  potentialDrugInteractions: string;
  potentialDrugInteractionFiles?: IFile[];
  rbc1: string;
  hgb1: string;
  htc1: string;
  plt1: string;
  wbc1: string;
  mcv1: string;
  mch1: string;
  mchc1: string;
  hcvRna1: string;
  albumin1: string;
  aiat1: string;
  bilirubin1: string;
  simplifiedDiagram: string;
  durationOfTherapy: string;
  rbc4: string;
  hgb4: string;
  htc4: string;
  plt4: string;
  wbc4: string;
  mcv4: string;
  mch4: string;
  mchc4: string;
  aiat4: string;
  bilirubin4: string;
  bilirubinMonitoring4: string;
  rbc8: string;
  hgb8: string;
  htc8: string;
  plt8: string;
  wbc8: string;
  mcv8: string;
  mch8: string;
  mchc8: string;
  aiat8: string;
  bilirubin8: string;
  bilirubinMonitoring8: string;
  rbc12: string;
  hgb12: string;
  htc12: string;
  plt12: string;
  wbc12: string;
  mcv12: string;
  mch12: string;
  mchc12: string;
  aiat12: string;
  bilirubin12: string;
  bilirubinMonitoring12: string;
  hcvRnaAfterTreatment: string;
  hcvRnaAfterTreatmentDesc?: string;
  aiatAfterTreatment?: string;
  abdominalUltrasoundAfterTreatment?: string;
  abdominalUltrasoundAfterTreatmentCheckbox: boolean;
  abdominalUltrasoundAfterTreatmentFiles?: IFile[];
  hcvRnaAfterTreatment12: string;
  abdominalUltrasoundAfterTreatment12: string;
  abdominalUltrasoundAfterTreatmentCheckbox12: boolean;
  abdominalUltrasoundAfterTreatmentFiles12?: IFile[];
  infoAboutTherapy: "";
  conclusion: "";
  conclusionCheckbox: false;
  conclusionFiles?: IFile[];
}
