import React from "react";
import {FormikErrors} from "formik";
import {observer} from "mobx-react";

// hooks
import {useStage02ViewModel} from "./Stage02.vm";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {Label} from "../../../../../../../../shared/ui/label/Label";
import {Input} from "../../../../../../../../shared/ui/input/Input";
import {SingleSelect} from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import {RadioBox} from "../../../../../../../../shared/ui/radioBox/RadioBox";
import {DateInput} from "../../../../../../../../shared/ui/date/Date";

// helpers, types, styles
import {likertOptions, scaleOptions, treatmentOptions} from "./Stage02.helper";
import {IDrugs} from "./type";
import {ButtonAddDrug} from "./Stage02.styled";
import theme from "../../../../../../../../../theme/theme";
import {RadioForm} from "../../../../../stages/firstStage/FirstStage.styled";

//icons
import {ReactComponent as Plus} from "../../../../../../../../../assets/icon/abbviePlus.svg";
import {ReactComponent as Bin} from "../../../../../../../../../assets/icon/abbvieBin.svg";

export const Stage02 = observer(() => {
  const {
    formik, setOption, addDrug, deleteDrug, resetForAxial, resetForCircumferential, resetForDas,
    resetForLikert
  } = useStage02ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data rozpoznania</>}
          isUpperCase={true}
          name={"diagnosisDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.diagnosisDate}
          onClose={() => {
            formik.setFieldTouched("diagnosisDate", true);
            formik.validateField("diagnosisDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("diagnosisDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("diagnosisDate", true);
            setTimeout(() => {
              formik.validateField("diagnosisDate");
            }, 0);
          }}
          isError={
            formik.touched.diagnosisDate && Boolean(formik.errors.diagnosisDate)
          }
        />
      </Grid>
      <Grid item pb={2} display={"flex"} flexDirection={"column"} mt={3}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Jaka postać ŁZS?"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={
              formik.touched.lzsCharacter && Boolean(formik.errors.lzsCharacter)
            }
            id={"lzsCharacter"}
            name={"lzsCharacter"}
            value={"postać osiowa"}
            checked={formik.values.lzsCharacter === "postać osiowa"}
            onChange={(e) => {
              formik.handleChange(e);
              resetForAxial()
            }}
          />
          <RadioBox
            isError={
              formik.touched.lzsCharacter && Boolean(formik.errors.lzsCharacter)
            }
            id={"lzsCharacter2"}
            name={"lzsCharacter"}
            value={"postać obwodowa"}
            checked={formik.values.lzsCharacter === "postać obwodowa"}
            onChange={(e) => {
              formik.handleChange(e)
              resetForCircumferential()
            }}
          />
        </RadioForm>
      </Grid>
      {formik.values.lzsCharacter === 'postać osiowa' && <>
        <Grid xs={12} item pb={2} display={"flex"} flexDirection={"column"} mt={2}>
          <Label
            size={"small"}
            isUpperCase={false}
            color={theme.colors.grey1}
            label={"KRYTERIA PsARC"}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.criteriaPsarc && Boolean(formik.errors.criteriaPsarc)
              }
              id={"criteriaPsarc"}
              name={"criteriaPsarc"}
              value={"das 28"}
              checked={formik.values.criteriaPsarc === "das 28"}
              onChange={(e) => {
                formik.handleChange(e)
                resetForDas()
              }}
            />
            <RadioBox
              isError={
                formik.touched.criteriaPsarc && Boolean(formik.errors.criteriaPsarc)
              }
              id={"criteriaPsarc2"}
              name={"criteriaPsarc"}
              value={"likert"}
              checked={formik.values.criteriaPsarc === "likert"}
              onChange={(e) => {
                formik.handleChange(e)
                resetForLikert()
              }}
            />
          </RadioForm>
        </Grid>
        {formik.values.criteriaPsarc === "das 28" && <>
          <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
            <Grid item pb={2} display={"flex"} flexDirection={"column"}>
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Wynik das 28"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    formik.touched.das28Result && Boolean(formik.errors.das28Result)
                  }
                  id={"das28Result"}
                  name={"das28Result"}
                  value={"crp"}
                  checked={formik.values.das28Result === "crp"}
                  onChange={formik.handleChange}
                />
                <RadioBox
                  isError={
                    formik.touched.das28Result && Boolean(formik.errors.das28Result)
                  }
                  id={"das28Result2"}
                  name={"das28Result"}
                  value={"ob"}
                  checked={formik.values.das28Result === "ob"}
                  onChange={formik.handleChange}
                />
              </RadioForm>
            </Grid>
            <Grid>
              <Input
                isRequired={true}
                label={"Wynik"}
                isUpperCase={true}
                name={"result"}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue('result', null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.result}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={formik.touched.result && Boolean(formik.errors.result)}
              />
            </Grid>
            <Grid
              item
              justifyContent={"space-between"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Wynik skali"}
              />
              <Typography variant="caption" color={theme.colors.grey1}>
                &lt;2,6 - remisja <br/>
                &le;3,2 – aktywność mała <br/>
                &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                &gt;5,1 – aktywność duża
              </Typography>
            </Grid>
          </Grid></>}
        {formik.values.criteriaPsarc === 'likert' && <>
          <Grid container display={"flex"} gap={"20px"} mt={2}>
            <Grid item xs={5}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.likertPatient && Boolean(formik.errors.likertPatient)
                }
                label={"Wynik w skali Likerta pacjent"}
                handleChange={(value) => setOption(value, "likertPatient")}
                selectedValue={formik.values.likertPatient ?? ""}
                elementName={"likertPatient"}
                options={likertOptions}
              />
            </Grid>
            <Grid>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.likertDoctor && Boolean(formik.errors.likertDoctor)
                }
                label={"Wynik w skali Likerta lekarz"}
                handleChange={(value) => setOption(value, "likertDoctor")}
                selectedValue={formik.values.likertDoctor ?? ""}
                elementName={"likertDoctor"}
                options={likertOptions}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={5}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.generalAssessment && Boolean(formik.errors.generalAssessment)
                }
                label={"OGÓLNA OCENA STANU CHOROBY"}
                handleChange={(value) => setOption(value, "generalAssessment")}
                selectedValue={formik.values.generalAssessment ?? ""}
                elementName={"generalAssessment"}
                options={scaleOptions}
              />
            </Grid>
          </Grid>
          <Grid container display={"flex"} gap={"20px"}>
            <Grid item xs={5}>
              <Input
                label={"Liczba bolesnych stawów"}
                isUpperCase={true}
                name={"tenderJointsCount"}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue('tenderJointsCount', null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.tenderJointsCount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.tenderJointsCount &&
                  Boolean(formik.errors.tenderJointsCount)
                }
              />
            </Grid>
            <Grid>
              <Input
                label={"Liczba obrzękniętych stawów"}
                isUpperCase={true}
                name={"swollenJointsCount"}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue('swollenJointsCount', null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.swollenJointsCount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.swollenJointsCount &&
                  Boolean(formik.errors.swollenJointsCount)
                }
              />
            </Grid>
          </Grid>
        </>}
      </>}
      {formik.values.lzsCharacter === 'postać obwodowa' && <>
        <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
          <Grid item pb={2} display={"flex"} flexDirection={"column"}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Podaj wynik w skali"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.resultScaleOption && Boolean(formik.errors.resultScaleOption)
                }
                id={"resultScaleOption"}
                name={"resultScaleOption"}
                value={"basdai"}
                checked={formik.values.resultScaleOption === "basdai"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={
                  formik.touched.resultScaleOption && Boolean(formik.errors.resultScaleOption)
                }
                id={"resultScaleOption2"}
                name={"resultScaleOption"}
                value={"asdas"}
                checked={formik.values.resultScaleOption === "asdas"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
          <Grid>
            <Input
              isRequired={true}
              label={"Wynik"}
              isUpperCase={true}
              name={"resultScale"}
              type={"number"}
              maxWidth={"163px"}
              onChangeCustom={() => formik.setFieldValue('resultScale', null)}
              colorLabel={theme.colors.grey1}
              value={formik.values.resultScale}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={formik.touched.resultScale && Boolean(formik.errors.resultScale)}
            />
          </Grid>
        </Grid>
        <Grid container display={"flex"} gap={"20px"}>
          <Grid item xs={5}>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                formik.touched.degreeOfBack && Boolean(formik.errors.degreeOfBack)
              }
              label={"Stopień bólu kręgosłupa"}
              handleChange={(value) => setOption(value, "degreeOfBack")}
              selectedValue={formik.values.degreeOfBack ?? ""}
              elementName={"degreeOfBack"}
              options={scaleOptions}
            />
          </Grid>
          <Grid>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                formik.touched.generalAssessment2 && Boolean(formik.errors.generalAssessment2)
              }
              label={"OGÓLNA OCENA STANU CHOROBY"}
              handleChange={(value) => setOption(value, "generalAssessment2")}
              selectedValue={formik.values.generalAssessment2 ?? ""}
              elementName={"generalAssessment2"}
              options={scaleOptions}
            />
          </Grid>
        </Grid>
      </>}
      <Divider
        style={{width: "100%"}}
        variant={"fullWidth"}
        sx={{height: "2px", margin: "16px 0 16px 0"}}
      />
      {formik.values.drugs?.map((drug, index) => (
        <Grid key={index} item xs={12}>
          <Grid
            item
            xs={12}
            mt={2}
            justifyContent={"space-between"}
            display={"flex"}
          >
            <Typography
              variant={"h6"}
              color={theme.colors.grey1}
              textTransform={"uppercase"}
              fontWeight={700}
            >
              Leczenie {index + 1}
            </Typography>
            {formik.values.drugs.length !== 1 && (
              <ButtonAddDrug type="button" onClick={() => deleteDrug(index)}>
                Usuń leczenie
                <Bin/>
              </ButtonAddDrug>
            )}
          </Grid>
          <Grid item xs={12} mt={1}>
            <SingleSelect
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                Array.isArray(formik.touched.drugs) &&
                formik.touched.drugs[index]?.treatment &&
                Array.isArray(formik.errors.drugs) &&
                Boolean(
                  (formik.errors.drugs[index] as FormikErrors<IDrugs>)
                    ?.treatment
                )
              }
              label={"Leczenie"}
              handleChange={(value) => setOption(value, "treatment", index)}
              selectedValue={formik.values.drugs[index]?.treatment || ""}
              elementName={`drugs[${index}].treatment`}
              options={treatmentOptions}
            />
          </Grid>
          {formik.values.drugs[index]?.treatment === 'Inne' && <Grid xs={12}>
            <Input
              label={"Inne"}
              isUpperCase={true}
              name={`drugs[${index}].treatmentOther`}
              type={"text"}
              onChangeCustom={() => formik.setFieldValue(`drugs[${index}].treatmentOther`, null)}
              colorLabel={theme.colors.grey1}
              value={formik.values.drugs?.[index]?.treatmentOther}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.drugs?.[index]?.treatmentOther &&
                Boolean(
                  (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.treatmentOther
                )
              }
            />
          </Grid>}
          <Grid
            item
            xs={12}
            justifyContent={"space-between"}
            pb={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Glikokortykosteroidy (GKS)"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  Array.isArray(formik.touched.drugs) &&
                  formik.touched.drugs[index]?.gks &&
                  Array.isArray(formik.errors.drugs) &&
                  Boolean(
                    (formik.errors.drugs[index] as FormikErrors<IDrugs>)?.gks
                  )
                }
                id={`gks1-${index}`}
                name={`drugs[${index}].gks`}
                value={"tak"}
                checked={formik.values.drugs[index].gks === "tak"}
                onChange={(e) =>
                  formik.setFieldValue(`drugs[${index}].gks`, e.target.value)
                }
              />
              <RadioBox
                isError={
                  Array.isArray(formik.touched.drugs) &&
                  formik.touched.drugs[index]?.gks &&
                  Array.isArray(formik.errors.drugs) &&
                  Boolean(
                    (formik.errors.drugs[index] as FormikErrors<IDrugs>)?.gks
                  )
                }
                id={`gks2-${index}`}
                name={`drugs[${index}].gks`}
                value={"nie"}
                checked={formik.values.drugs[index].gks === "nie"}
                onChange={(e) =>
                  formik.setFieldValue(`drugs[${index}].gks`, e.target.value)
                }
              />
            </RadioForm>
          </Grid>
          <Grid container display={"flex"} gap={"20px"} alignItems={"flex-end"}>
            <Grid>
              <Input
                isRequired={true}
                label={"Dawka"}
                isUpperCase={true}
                name={`drugs[${index}].dose`}
                type={"number"}
                onChangeCustom={() => formik.setFieldValue(`drugs[${index}].dose`, null)}
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.drugs?.[index]?.dose}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.drugs?.[index]?.dose &&
                  Boolean(
                    (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.dose
                  )
                }
              />
            </Grid>
            <Grid pb={2}>
              <RadioForm>
                <RadioBox
                  id={`unit${index}1`}
                  name={`drugs[${index}].unit`}
                  value={"g/m"}
                  isUpperCase={false}
                  checked={drug.unit === "g/m"}
                  onChange={(e) => {
                    formik.setFieldValue(`drugs[${index}].unit`, e.target.value);
                    formik.setFieldValue(`drugs[${index}].otherUnit`, '')
                  }}
                  isError={
                    formik.touched.drugs?.[index]?.unit &&
                    Boolean(
                      (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)
                        ?.unit
                    )
                  }
                />
                <RadioBox
                  id={`unit${index}2`}
                  name={`drugs[${index}].unit`}
                  value={"mg/kg m.c."}
                  isUpperCase={false}
                  checked={drug.unit === "mg/kg m.c."}
                  onChange={(e) => {
                    formik.setFieldValue(`drugs[${index}].unit`, e.target.value)
                    formik.setFieldValue(`drugs[${index}].otherUnit`, '')
                  }}
                  isError={
                    formik.touched.drugs?.[index]?.unit &&
                    Boolean(
                      (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)
                        ?.unit
                    )
                  }
                />
                <RadioBox
                  id={`unit${index}3`}
                  name={`drugs[${index}].unit`}
                  value={"Inna jednostka"}
                  isUpperCase={false}
                  checked={drug.unit === "Inna jednostka"}
                  onChange={(e) =>
                    formik.setFieldValue(`drugs[${index}].unit`, e.target.value)
                  }
                  isError={
                    formik.touched.drugs?.[index]?.unit &&
                    Boolean(
                      (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)
                        ?.unit
                    )
                  }
                />
              </RadioForm>
            </Grid>
            {formik.values.drugs[index].unit === "Inna jednostka" && (
              <Grid>
                <Input
                  isRequired={true}
                  label={"Inna jednostka"}
                  isUpperCase={true}
                  name={`drugs[${index}].otherUnit`}
                  type={"text"}
                  maxWidth={"163px"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.drugs?.[index]?.otherUnit || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.drugs?.[index]?.otherUnit &&
                    Boolean(
                      (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)
                        ?.otherUnit
                    )
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Input
              before={
                <Label
                  isUpperCase={false}
                  style={{margin: "8px 0 0"}}
                  color={theme.colors.orange}
                  size={"superSmall"}
                  label={"liczba tygodni"}
                />
              }
              maxWidth={"72px"}
              isRequired={true}
              label={"Zakres czasu przyjmowania"}
              isUpperCase={true}
              name={`drugs[${index}].doseRange`}
              type={"number"}
              onChangeCustom={() => formik.setFieldValue(`drugs[${index}].doseRange`, null)}
              colorLabel={theme.colors.grey1}
              value={formik.values.drugs?.[index]?.doseRange}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.drugs?.[index]?.doseRange &&
                Boolean(
                  (formik.errors.drugs?.[index] as FormikErrors<IDrugs>)
                    ?.doseRange
                )
              }
            />
          </Grid>
          <Divider
            style={{width: "100%"}}
            variant={"fullWidth"}
            sx={{height: "2px", margin: "0 0 16px 0"}}
          />
        </Grid>
      ))}
      <Grid display={"flex"} item xs={12} justifyContent={"center"} mt={2}>
        <ButtonAddDrug type="button" onClick={addDrug}>
          Dodaj leczenie
          <Plus/>
        </ButtonAddDrug>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Input
          label={"Komentarz istotny klinicznie"}
          isUpperCase={true}
          name={'relevantComment'}
          type={"textarea"}
          colorLabel={theme.colors.grey1}
          value={formik.values.relevantComment || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          background={theme.colors.white}
          placeholder={"Wpisz"}
          isError={
            formik.touched.relevantComment &&
            Boolean(formik.errors.relevantComment)
          }
        />
      </Grid>
    </Grid>
  );
});
