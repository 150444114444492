import {observer} from "mobx-react";
import React from "react";

// hooks
import {useLzsrStore} from "../../../../../../../../store/hooks";

// components
import {Grid, Typography} from "@mui/material";
import {GroupForm, Label, NormalText} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import theme from "../../../../../../../../theme/theme";

export const CurrentTreatmentSection = observer(() => {
  const caseStore = useLzsrStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Dotychczasowe leczenie:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={4}>
            <Label>DATA ROZPOZNANIA</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.diagnosisDate))}
            </NormalText>
          </Grid>
          <Grid item xs={12}>
            <Label>JAKA POSTAĆ ŁZS</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.lzsCharacter)}
            </NormalText>
          </Grid>
          {caseElement.lzsCharacter === 'postać osiowa' && <>
            <Grid mt={4}>
              <Grid item xs={12}>
                <Label $isNoneCase={true}>KRYTERIA PsARC</Label>
                <NormalText>{checkValueIsEmpty(caseElement.criteriaPsarc)}</NormalText>
              </Grid>
            </Grid>
            {caseElement.criteriaPsarc === 'das 28' && <Grid container gap={'20px'} mb={4}>
              <Grid item xs={2}>
                <Label>WYNIK DAS 28</Label>
                <NormalText>{checkValueIsEmpty(caseElement.das28Result)}</NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(caseElement.result)}</NormalText>
              </Grid>
              <Grid item>
                <Label>WYNIK skali</Label>
                <NormalText> &lt;2,6 - remisja <br/>
                  &le;3,2 – aktywność mała <br/>
                  &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                  &gt;5,1 – aktywność duża</NormalText>
              </Grid>
            </Grid>}
            {caseElement.criteriaPsarc === 'likert' && <><Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>Wynik w skali Likerta pacjent</Label>
                <NormalText>{checkValueIsEmpty(caseElement.likertPatient)}</NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>Wynik w skali Likerta lekarz</Label>
                <NormalText>{checkValueIsEmpty(caseElement.likertDoctor)}</NormalText>
              </Grid>
            </Grid>
              <Grid item xs={12} mt={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>{checkValueIsEmpty(caseElement.generalAssessment)}</NormalText>
              </Grid>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>LICZBA BOLESNYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(caseElement.tenderJointsCount)}
                  </NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(caseElement.swollenJointsCount)}
                  </NormalText>
                </Grid>
              </Grid>
            </>}
          </>}
          {caseElement.lzsCharacter === 'postać obwodowa' && <>
            <Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>podaj wynik w skali</Label>
                <NormalText>{checkValueIsEmpty(caseElement.resultScaleOption)}</NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(caseElement.resultScale)}</NormalText>
              </Grid>
            </Grid>
            <Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>Stopień bólu kręgosłupa</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.degreeOfBack)}
                </NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.generalAssessment2)}
                </NormalText>
              </Grid>
            </Grid>
          </>}
        </Grid>
      </Grid>

      {caseElement.drugs.map((drug, index) => (
        <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
          <Grid item xs={12} mb={2}>
            <Label $size={"large"} $mb={0}>
              {`Leczenie ${index + 1}`}
            </Label>
          </Grid>
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Label>LECZENIE</Label>
              <NormalText>{checkValueIsEmpty(drug.treatment)}</NormalText>
            </Grid>
            {drug.treatmentOther && <Grid item xs={12}>
              <Label>INNE</Label>
              <NormalText>{checkValueIsEmpty(drug.treatmentOther)}</NormalText>
            </Grid>}
            <Grid item xs={12}>
              <Label>GLIKOKORTYKOSTEROIDY (GKS)</Label>
              <NormalText>{checkValueIsEmpty(drug.gks)}</NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label>DAWKA</Label>
              <NormalText>
                {checkValueIsEmpty(drug.dose)}{" "}
                {checkValueIsEmpty(drug.unit === 'Inna jednostka' ? drug.otherUnit : drug.unit)}
              </NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label $mb={0}>ZAKRES CZASU PRZYJMOWANIA</Label>
              <Typography variant={'caption'} color={theme.colors.orange}>liczba
                tygodni</Typography>
              <NormalText>{checkValueIsEmpty(drug.doseRange)}</NormalText>
            </Grid>
          </Grid>
        </Grid>))}
      <Grid container mt={5} bgcolor={"white"} p={2}>
        <Grid item xs={12}>
          <Label>Komentarz istotny klinicznie</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.relevantComment)}
          </NormalText>
        </Grid>
      </Grid>
    </>
  );
});
