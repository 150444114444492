import {observer} from "mobx-react";
// hooks
import {useCaseSettingsStore} from "../../../../../store/hooks";
// components
import {EnFormNames} from "../../../../../store/caseSettingsStore";
import {HcvForm} from "../forms/virusology/hcv/HcvForm";
import {RzsForm} from "../forms/rheumatology/rzs/RzsForm";
import {LzsSkyriziForm} from "../forms/rheumatology/lzs-skyrizi/LzsSkyriziForm";
import {LzsRinvoqForm} from "../forms/rheumatology/lzs-rinvoq/LzsRinvoqForm";
import {ZzskForm} from "../forms/rheumatology/zzsk/ZzskForm";

export const CaseCreator = observer(() => {
  const {currentForm} = useCaseSettingsStore();

  if (currentForm === EnFormNames.hcv) {
    return <HcvForm/>;
  }

  if (currentForm === EnFormNames.rzs) {
    return <RzsForm/>;
  }

  if (currentForm === EnFormNames.lzss) {
    return <LzsSkyriziForm/>;
  }

  if (currentForm === EnFormNames.lzsr) {
    return <LzsRinvoqForm/>;
  }

  if (currentForm === EnFormNames.zzsk) {
    return <ZzskForm/>;
  }

  return null;
});
