import React from "react";
import { IOption } from "../../../../interfaces/common/form/option";
import { RequiredStar, SelectContainer, StyledSelect } from "./Select.styled";
import { Label } from "../label/Label";
import theme from "../../../../theme/theme";

interface IProps {
  selectValue: number | null;
  elementName: string;
  // TODO: delete this prop and replace it with handleChange every functions in the code
  setValue?: (value: number) => void;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  optionsArray: IOption[];
  defaultValue?: string;
  label?: string;
  isRequired?: boolean;
  multiple?: boolean;
  isError?: boolean;
  disabled?: boolean;
  color?: string;
  colorLabel?: string;
  borderColor?: string;
  background?: string;
  placeholderColor?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<IProps> = ({
                                           multiple,
                                           label,
                                           isError,
                                           isRequired,
                                           selectValue,
                                           elementName,
                                           defaultValue,
                                           style,
                                           containerStyle,
                                           setValue,
                                           handleChange,
                                           optionsArray,
                                           color,
                                           colorLabel,
                                           borderColor,
                                           background,
                                           onBlur,
                                           disabled,
                                           placeholderColor,
                                         }) => {
  return (
    <SelectContainer style={containerStyle}>
      {label && (
        <Label
          color={colorLabel}
          size={"small"}
          label={
            <>
              {label}
              {isRequired && <RequiredStar color={colorLabel}>*</RequiredStar>}
            </>
          }
        />
      )}
      <StyledSelect
        multiple={multiple}
        required
        defaultValue={defaultValue}
        name={elementName}
        value={selectValue || ''}
        onBlur={onBlur}
        disabled={disabled}
        onChange={(e) => {
          setValue && setValue(+e.target.value);
          handleChange && handleChange(e);
        }}
        id={elementName}
        $border={borderColor}
        $placeholderColor={placeholderColor}
        $background={disabled ? theme.colors.grey6 : background}
        $color={color}
        $isError={isError || false}
        style={style}
      >
        {defaultValue && (
          <option disabled value={-1}>
            {defaultValue}
          </option>
        )}

        {optionsArray?.map((option, index: number) => (
          <option key={index} value={option.id}>
            {option.title}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
};
