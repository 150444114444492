export interface LabelProps {
  $size?: "small" | "normal" | "large";
  $mb?: number;
  $isNoneCase?: boolean
}

export const checkValueIsEmpty = (value?: string | number | null) => {
  if (value === '') return '-'
  return value ?? "-";
};
