import { IFirstStage } from "../../../../../interfaces/user/draftCase/firstStage";
import {
  IDrug,
  ISecondStage,
} from "../../../../../interfaces/user/draftCase/secondStage";
import { IThirdStage } from "../../../../../interfaces/user/draftCase/thirdStage";
import { IFourthStage } from "../../../../../interfaces/user/draftCase/fouthStage";

export const setAllFieldsTouched = (
  values: IFirstStage | ISecondStage | IThirdStage | IFourthStage | IDrug,
  handleFunction: (field: string, value: boolean) => void
) => {
  Object.keys(values)?.forEach((fieldName) => {
    if (fieldName) {
      handleFunction(fieldName, true);
    }
  });
};
